import {
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT_REQUEST,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FAILURE,
  GET_AUTHENTICATED_USER_REQUEST,
  GET_AUTHENTICATED_USER_SUCCESS,
  GET_AUTHENTICATED_USER_FAILURE,
  GET_LOGGED_IN_USER_INFO_REQUEST,
  GET_LOGGED_IN_USER_INFO_SUCCESS,
  GET_LOGGED_IN_USER_INFO_FAILURE,
} from './types';

const initialState = {
  isAuthenticatingError: '',
  isAuthenticating: true,
  signingUp: false,
  signupError: '',
  loggingIn: false,
  loginError: '',
  isLoggedIn: false,
  signingOut: false,
  signOutError: '',
  gettingLoggedInUserInfo: false,
  loggedInUserInfoError: '',
  loggedInUserInfo: {},
};
export default function (state = initialState, action) {
  switch (action.type) {
    case SIGN_UP_REQUEST:
      return {
        ...state,
        signingUp: true,
      };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        signingUp: false,
      };
    case SIGN_UP_FAILURE:
      return {
        ...state,
        signingUp: false,
        signupError: action.error,
      };
    case SIGN_IN_REQUEST:
      return {
        ...state,
        loggingIn: true,
      };
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        isLoggedIn: true,
        loggedInUserInfo:action.result
      };
    case SIGN_IN_FAILURE:
      return {
        ...state,
        loggingIn: false,
        loginError: action.error,
      };
    case SIGN_OUT_REQUEST:
      return {
        ...state,
        signingOut: true,
      };
    case SIGN_OUT_SUCCESS:
      return {
        ...state,
        signingOut: false,
        isLoggedIn: false,
      };
    case SIGN_OUT_FAILURE:
      return {
        ...state,
        signingOut: false,
        signOutError: action.error,
      };
    case GET_AUTHENTICATED_USER_REQUEST:
      return {
        ...state,
      };
    case GET_AUTHENTICATED_USER_SUCCESS:
      return {
        ...state,
        isAuthenticating: false,
        isLoggedIn: true,
      };
    case GET_AUTHENTICATED_USER_FAILURE:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticatingError: action.error,
        isLoggedIn: false,
      };
    case GET_LOGGED_IN_USER_INFO_REQUEST:
      return {
        ...state,
        gettingLoggedInUserInfo: true
      };
    case GET_LOGGED_IN_USER_INFO_SUCCESS:
      return {
        ...state,
        gettingLoggedInUserInfo: false,
        loggedInUserInfo: action.result,
      };
    case GET_LOGGED_IN_USER_INFO_FAILURE:
      return {
        ...state,
        gettingLoggedInUserInfo: false,
        getLoggedInUserInfoError: action.error,
      };
    default:
      return state;
  }
}
