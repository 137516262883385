import React from 'react';

import styles from './divider.module.scss';
import cx from 'classnames';

const Divider = ({ className }) => {
  return <div className={cx(className, styles.divider)}></div>;
};

export default Divider;
