import {
  ADD_PERSON_TO_WAITLIST_REQUEST,
  ADD_PERSON_TO_WAITLIST_SUCCESS,
  ADD_PERSON_TO_WAITLIST_FAILURE,
} from './types';

const initialState = {
  addingPersonToWaitlist: false,
  addingPersonToWaitlistError: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_PERSON_TO_WAITLIST_REQUEST:
      return {
        ...state,
        addingPersonToWaitlist: true,
      };
    case ADD_PERSON_TO_WAITLIST_SUCCESS:
      return {
        ...state,
        addingPersonToWaitlist: false,
      };
    case ADD_PERSON_TO_WAITLIST_FAILURE:
      return {
        ...state,
        addingPersonToWaitlist: false,
        addingPersonToWaitlistError: action.error,
      };

    default:
      return state;
  }
}
