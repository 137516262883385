export const OnCreateUser = `
    subscription OnCreateUser {
        onCreateUser {
            id
            username
            createdAt
        }
    }
`

export const OnCreateUserConversation = `
  subscription OnCreateUserConversation($userId: ID!) {
    onCreateConvoLink(convoLinkUserId:$userId) {
      id
      convoLinkUserId
      convoLinkConversationId
      conversation {
        id
        name
        members
        updatedAt
        createdAt
        messages {
          items {
            id
            authorId
            content
            read
            type
            by
            have
            need
            preferredRate
            messageConversationId
            createdAt
          }
        }
      }
    }
  }
`

export const OnCreateMessage = `
  subscription OnCreateMessage($conversationId: ID!) {
    onCreateMessage(messageConversationId: $conversationId) {
      id
      content
      type
      read
      by
      have
      need
      preferredRate
      authorId
      messageConversationId
      createdAt
    }
  }
`
export const OnUpdateMessage = `
  subscription OnUpdateMessage($conversationId: ID!) {
    onUpdateMessage(messageConversationId: $conversationId) {
      id
      content
      type
      read
      by
      have
      need
      preferredRate
      authorId
      messageConversationId
      createdAt
    }
  }
`
export const OnCreateMessageListener = `
  subscription OnCreateMessageListener {
    onCreateMessageListener {
      id
      content
      type
      read
      by
      have
      need
      preferredRate
      authorId
      messageConversationId
      createdAt
    }
  }
`
export const OnUpdateConvo = `
  subscription OnUpdateConvo {
    onUpdateConvo {
      id
      name
      members
      createdAt
      updatedAt
    }
  }
`