import React, { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Storage, Auth } from 'aws-amplify';
import { FormField } from 'src/ui/Form';
import { useForm, Controller } from 'react-hook-form';
import VerificationFlow from 'src/components/Verification/VerificationFlow';

import AccountSummaryCard from 'src/ui/Cards/AccountSummaryCard';
import Flyout from 'src/ui/Flyout';

import styles from './app-home-page.module.scss';

import Avatar from 'src/ui/Avatar';
import PhotoPicker from 'src/ui/PhotoPicker';

const AppHomePage = (props) => {
  const [accountSetUpFlow, setAccountSetUpFlow] = useState(false);
  const [verificationStep, setVerificationStep] = useState(1);
  const [pickerState, setPickerState] = useState({ loading: false, file: '' });
  let { path, url } = useRouteMatch();

  // useEffect(() => {
  //   getImage()
  // }, [])

  const getImage = async () => {
    const key = 'us-east-1:3c70b8b9-bc80-434a-b380-d077d2057cb0/1612005101212';
    const { identityId } = await Auth.currentCredentials();

    // const files = await Storage.list('');

    // console.log(files)

    const signedUrl = await Storage.get(key, {
      //download: true,
      level: 'private', // defaults to `public`
      identityId, // id of another user, if `level: protected`
      contentType: 'image/jpeg', // set return content type, eg "text/html"
    });

    //setPickerState({...pickerState, file: signedUrl})

    console.log(signedUrl);
  };

  const uploadImage = async () => {
    const visibility = 'public';
    const { identityId } = await Auth.currentCredentials();
    console.log(identityId);
    const filename = `/${identityId}/${Date.now()}`;
    const uploadedFile = await Storage.put(filename, pickerState.file, {
      level: 'public',
      contentType: 'image/*',
    });

    console.log(uploadedFile.key);
  };

  const handleFileChange = (event) => {
    const { target } = event;
    const { files } = target;

    if (files && files[0]) {
      var reader = new FileReader();

      reader.onloadstart = () => setPickerState({ ...pickerState, loading: true });

      reader.onload = (event) => {
        console.log(event.target);
        setPickerState({
          file: event.target.result,
          loading: false,
        });
      };

      reader.readAsDataURL(files[0]);
    }
  };

  const handleSetupAccount = (data) => {
    setVerificationStep(2);
  };
  const handleVerifyPhone = (data) => {
    setVerificationStep(3);
  };
  const handleSubmitVerificationCode = (data) => {
    setVerificationStep(4);
  };
  const handleSelectCountry = (data) => {
    setVerificationStep(5);
  };
  const handleFundWallet = (data) => {
    setVerificationStep(6);
  };
  const { loading, file } = pickerState;
  return (
    <>
      {/* <h2>App Home Pae</h2>
      <ul>
        <li>
          <Link to={`${url}/rendering`}>Rendering with React</Link>
        </li>
        <li>
          <Link to={`${url}/components`}>Components</Link>
        </li>
        <li>
          <Link to={`${url}/props-v-state`}>Props v. State</Link>
        </li>
      </ul>

      <Switch>
        <Route exact path={path}>
          <h3>Please select a topic.</h3>
          <Link to={`messages/null`}>messages</Link>
        </Route>
        <Route exact path={`${path}/:topicId`}>
          <AccountSummaryCard />
        </Route>
      </Switch> */}
      <div className={styles.appHome}>
        <FormField
          onChange={(value, data) => console.log(value, data)}
          label="Phone"
          placeholder="+234 XXXX XXXX XXX"
          type="phone"
          name="phone"
        />
        <PhotoPicker onChange={handleFileChange}>
          <Avatar size="M" imageUri={file} />
        </PhotoPicker>

        <button onClick={uploadImage}>upload image</button>

        <button onClick={() => setAccountSetUpFlow(true)}>check out new on boarding flow</button>
        <Link to="/messages">
          <button>checkout new messages ui</button>
        </Link>
      </div>
      <Flyout
        size="L"
        title={null}
        show={accountSetUpFlow}
        handleClose={() => setAccountSetUpFlow(false)}
      >
        <VerificationFlow
          verificationStep={verificationStep}
          setVerificationStep={setVerificationStep}
          handleFundWallet={handleFundWallet}
          handleSelectCountry={handleSelectCountry}
          handleSetupAccount={handleSetupAccount}
          handleSubmitVerificationCode={handleSubmitVerificationCode}
          handleVerifyPhone={handleVerifyPhone}
        />
      </Flyout>
    </>
  );
};

export default AppHomePage;
