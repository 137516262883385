import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './Flyout.module.scss';
import { AppLogo, FlyOutCloseIcon } from 'src/assets/svgs';

const Flyout = ({
  headerBg,
  children,
  className,
  isDismissable,
  handleClose,
  noMask,
  size,
  show,
  title,
  desc,
  icon,
  ...rest
}) => {
  const flyoutRef = useRef(null);

  useEffect(() => {
    const flyout = flyoutRef.current;

    // const eventHandler = (e) => {
    //   if (!noMask) {
    //     if (flyout && show && !flyout.contains(e.target)) return handleClose();
    //   }
    // };
    // if (typeof document !== `undefined`) {
    //   document.addEventListener('click', eventHandler);
    // }

    // return () => {
    //   if (typeof document !== `undefined`) {
    //     document.removeEventListener('click', eventHandler);
    //   }
    // };
  }, [handleClose, show, noMask]);

  return (
    <>
      <section
        className={cx(className, styles.flyout, {
          [styles.flyout_small]: size === 'S',
          [styles.flyout_medium]: size === 'M',
          [styles.flyout_large]: size === 'L',
          [styles.flyout_hide]: !show,
        })}
        {...rest}
        ref={flyoutRef}
      >
        <header
          className={cx(styles.flyout_header, {
            [styles.flyout_header_light]: headerBg === 'LIGHT',
          })}
        >
          {icon && icon()}
          {title && <h1 className={styles.flyout_header_title}>{title}</h1>}
          {desc && <p className={styles.flyout_header_desc}>{desc}</p>}
          {isDismissable && (
            <FlyOutCloseIcon onClick={handleClose} className={styles.flyout_header_closeIcon} />
          )}
        </header>
        <main className={styles.flyout_content}>{children}</main>
      </section>
      {show && !noMask && <div className={styles.flyout_mask} onClick={handleClose} />}
    </>
  );
};

Flyout.defaultProps = {
  noMask: false,
  isDismissable: true,
  headerBg: 'LIGHT',
  icon: () => <AppLogo/>
};
Flyout.propTypes = {
  headerBg: PropTypes.string,
  className: PropTypes.string,
  isDismissable: PropTypes.bool,
  handleClose: PropTypes.func,
  noMask: PropTypes.bool,
  // size: 'S' | 'M' | 'L' | 'FS',
  show: PropTypes.bool,
  title: PropTypes.string,
  desc: PropTypes.string,
};

export default Flyout;
