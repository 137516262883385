import React, { useState } from 'react';

import WaitList from 'src/components/Auth/WaitList';

import styles from './hero.module.scss';

import { connect } from 'react-redux';
import { addPersonToWaitList } from 'src/redux/modules/persons';
import { notifySuccess } from 'src/helpers/libs/toast';

const Hero = ({ addPersonToWaitList, addingPersonToWaitlist }) => {
  
  const [selected, setSelected] = useState('');

  const onAddToWaitList = (data) => {
    const userInfo = {
      ...data,
      discoveredUsFrom: selected,
    };
    addPersonToWaitList(userInfo, () => notifySuccess('Thank You !!! 🚀🚀🚀 ', `You have been added to the waitlist. henceforth, you'll receive notifications about Troperial's latest updates!`));
  };

  return (
    <header className={styles.hero}>
      <div className={styles.hero_bg}></div>
      <main className={styles.hero_wrapper}>
        <h1 className={styles.hero_wrapper_intro}>
          Exchange <span className={styles.hero_wrapper_intro_variant}>Currencies</span>{' '}
          <span role="img">💰</span> at your Preferred Rate
        </h1>
        <p className={styles.hero_wrapper_desc}>
          A fairer and faster way to exchange currencies across borders
        </p>

        <div className={styles.hero_wrapper_card}>
          <h2 className={styles.hero_wrapper_card_header}>Join the waitlist.</h2>
          <WaitList
            addingPersonToWaitlist={addingPersonToWaitlist}
            onSubmit={onAddToWaitList}
            selected={selected}
            onSelect={(selected) => {
              setSelected(selected.value);
            }}
          />
        </div>
      </main>
    </header>
  );
};
const mapStateToProps = (state) => ({
  addingPersonToWaitlist: state.persons.addingPersonToWaitlist,
});

const mapDispatchToProps = { addPersonToWaitList };

export default connect(mapStateToProps, mapDispatchToProps)(Hero);
