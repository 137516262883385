import React from 'react';

import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Login from 'src/components/Auth/Login';
import AuthLayout from 'src/layouts/auth-layout';

import { linkToAppHomePage } from 'src/data/pageRoutes';

import { signInUser } from 'src/redux/modules/auth';
import {
  getUserFromDynamoDb,
  addUserToDynamoDb,
  getUserAndConversations,
} from 'src/redux/modules/chat';

const LoginPage = ({
  signInUser,
  loggingIn,
  getUserFromDynamoDb,
  addUserToDynamoDb,
  getUserAndConversations,
}) => {
  const location = useHistory();

  const signInSuccessCb = (user) => {
    const userName = user['custom:userName'];
    location.push(linkToAppHomePage);
    getUserFromDynamoDb(userName, (user) => getUserFromDynamoDbSuccessCb(user, userName));
  };

  const getUserFromDynamoDbSuccessCb = (user, userName) => {
    user === null
      ? addUserToDynamoDb({ id: userName, username: userName }, () =>
          loadConversations(userName)
        )
      :  loadConversations(userName);
  };

  const loadConversations = (userName) => {
    getUserAndConversations('default', userName, (result) => {
    })
  }

  const handleSignIn = ({ email, password }) => {
    const input = { email, password };
    signInUser(input, signInSuccessCb);
  };

  return (
    <AuthLayout>
      <Login onSubmit={handleSignIn} loggingIn={loggingIn} />
    </AuthLayout>
  );
};

const mapStateToProps = (state) => ({
  loggingIn: state.auth.loggingIn,
});

const mapDispatchToProps = {
  signInUser,
  getUserFromDynamoDb,
  addUserToDynamoDb,
  getUserAndConversations,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
