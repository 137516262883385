import React, { useState } from 'react';

import Label from 'src/ui/Label';
import Button from 'src/ui/Button';

import styles from './select-country.module.scss';
import { FormField } from 'src/ui/Form';

const SelectCountry = ({ onSubmit, setVerificationStep }) => {
  const [selected, setSelected] = useState('');

  const options = [
    { value: 'NGN', label: 'Nigeria (Naira)' },
    { value: 'USD', label: 'United States (US Dollars)' },
    { value: 'CAD', label: 'Canada (Canadian Dollars)' },
    { value: 'GBP', label: 'Great Britain (Pounds)' },
    // { value: 'EUR', label: 'Euro' },
  ];

  const handleContinue = () => {
    console.log(selected);
    onSubmit(selected);
  };
  return (
    <div className={styles.selectCountry}>
      <Label
        title={() => <h1 className={styles.selectCountry_heading}>Choose a country for trading</h1>}
        subTitle=""
      />
      <div className={styles.selectCountry_form}>
        <FormField
          type="select"
          className={styles.selectCountry_form_select}
          onChange={(value) => setSelected(value)}
          value={selected.value}
          options={options}
        />
        <Button variant="themed" block onClick={handleContinue}>
          Continue
        </Button>
      </div>
      <Button
        className={styles.selectCountry_prevBtn}
        block
        variant="none"
        onClick={() => setVerificationStep(3)}
      >
        Previous step
      </Button>
    </div>
  );
};

export default SelectCountry;
