import React from 'react';

import './About.css';

import { ABOUT_PAGE_HEADING, ABOUT_PAGE_VISION, TEAM_LIST } from '../../data/text';

const About = () => {
  return (
    <section className="about__container">
      <header className="about__header">
        <div className="page-introduction-title">
          <div className="flex-container">
            <p>Our Mission</p>
            <h1 className="about-title">{ABOUT_PAGE_HEADING}</h1>
          </div>
        </div>
      </header>
      <section className="story__section">
        <div className="story">
          <h1 className="story_heading">The Troperial Story</h1>
          <p className="story_first-paragraph">
            In the past years, we have witnessed a large disruption in the mobile money and
            transfers sect. These new changes have made business transactions easier, although at a
            high cost to some. This is whereTroperial says 'I'm here to help'. Working with our team
            of experts, Troperial was created to fix the problem of high transaction costs and the
            divide between traders.
          </p>
          <p className="story_second-paragraph">
            Here at Troperial, we offer an ever-evolving payment experience designed to foster
            quicker and fairer ways to exchange currencies, and the good part is, it's all at your
            prefered rate.
          </p>
        </div>
        <div className="largeScreen-vision vision">
          <h1>Our Vision</h1>
          <p>{ABOUT_PAGE_VISION}</p>
        </div>
      </section>
      <section className="team">
        <div className="team_container">
          <h1 className="team_heading">Our Team</h1>
          <main className="team_grid">
            {TEAM_LIST.map((teamMate) => (
              <div className="teamMate" key={teamMate.id}>
                <img className="teamMate_image" src="" alt="" />
                <h1 className="teamMate_name">{teamMate.name}</h1>
                <h2 className="teamMate_role">{teamMate.role}</h2>
                <div dangerouslySetInnerHTML={{ __html: teamMate.desc }}></div>
              </div>
            ))}
          </main>
        </div>
        <div className="smallerScreen-vision vision">
          <h1>Our Vision</h1>
          <p>{ABOUT_PAGE_VISION}</p>
        </div>
      </section>
    </section>
  );
};

export default About;
