import React, { useState, useEffect, useRef } from 'react';
import { NavLink, Link, useHistory, useLocation } from 'react-router-dom';

import cx from 'classnames';

import Avatar from '../../ui/Avatar';

import styles from './app-navbar.module.scss';

import {
  AppLogo,
  Hamburger,
  HomeIcon,
  ListingIcon,
  MessagesIcon,
  NotificationsIcon,
  ProfileIcon,
} from 'src/assets/svgs';
import Badge from 'src/ui/Badge';

function Navbar({ signOut, unreadMessagesLength, ...props }) {
  // Refs
  const container = useRef(null);
  const mobileContainer = useRef(null);

  // useState Hooks
  const [dropdown, setDropdown] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  // Event handlers and logics
  const history = useHistory();
  const location = useLocation();
  let pathname = location.pathname.split('/')[1];

  const handleClickOutside = (event) => {
    if (container.current && !container.current.contains(event.target)) {
      setDropdown(false);
    }
  };
  const handleClickOutsideMobile = (event) => {
    if (mobileContainer.current && !mobileContainer.current.contains(event.target)) {
      setShowMobileMenu(false);
    }
  };

  const handleSignOut = () => {
    signOut(() => location.push('/signin'));
    setShowMobileMenu(false);
    setDropdown(false);
  };
  const setPathIcon = (pathname) => {
    switch (pathname) {
      case 'listings':
        return <ListingIcon />;
      case 'profile':
        return <ProfileIcon />;
      case 'messages':
        return <MessagesIcon />;
      case 'notifications':
        return <NotificationsIcon />;
      default:
        return <HomeIcon />;
    }
  };

  const routes = [
    {
      id: '01',
      path: '/home',
      className: 'home',
      onClick: () => {},
      Label: (props) => <p {...props}>Home</p>
    },
    {
      id: '04',
      path: '/messages',
      className: 'messages',
      onClick: () => props.setunreadMessagesLength(0),
      Label: (props) => <p {...props}>Messages <Badge number={unreadMessagesLength}/></p>
    },
    {
      id: '03',
      path: '/notifications',
      className: 'notifications',
      onClick: () => {},
      Label: (props) => <p {...props}>Notifications</p>,
    },
  ];

  //  Click out useEffect
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('mousedown', handleClickOutsideMobile);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('mousedown', handleClickOutsideMobile);
    };
  });
  const mobileNav = () => (
    <nav ref={mobileContainer} className={cx(styles.MobileNav)}>
      <div className={styles.MobileNav_header}>
        <Hamburger
          className={styles.MobileNav_header_hamburger}
          onClick={() => setShowMobileMenu(!showMobileMenu)}
        />
        <div className={styles.MobileNav_header_icon}>{setPathIcon(pathname)}</div>
        <Link to="/profile">
          <Avatar className={styles.MobileNav_header_avatar} />
        </Link>
      </div>
      <ul
        className={cx(styles.MobileNav_menu, {
          [styles.MobileNav_menu_hide]: !showMobileMenu,
        })}
      >
        {routes.map(({ id, path, className, Label, onClick }) => (
          <li key={id} className={styles.MobileNav_menu_menuItem}>
            <NavLink
              to={path}
              activeClassName={styles.MobileNav_menu_menuItem_link_active}
              className={styles.MobileNav_menu_menuItem_link}
              onClick={() => {
                setShowMobileMenu(false)
                onClick();
              }}
            >
            <Label/>
            </NavLink>
          </li>
        ))}
        <li className={styles.MobileNav_menu_menuItem}>
          <Link to="#" className={styles.MobileNav_menu_menuItem_link} onClick={handleSignOut}>
            <p className={styles.MobileNav_menu_menuItem_link_label}>Logout</p>
          </Link>
        </li>
        <li className={styles.MobileNav_menu_menuItem}>
          <Link
            onClick={() => setShowMobileMenu(false)}
            to="/"
            className={styles.MobileNav_menu_menuItem_link}
          >
            <AppLogo className={styles.MobileNav_menu_menuItem_link_logo} />
          </Link>
        </li>
      </ul>
    </nav>
  );
  return (
    <>
      {mobileNav()}
      <nav className={styles.nav} ref={container}>
        <AppLogo onClick={() => history.push('/')} className={styles.nav_logo} />
        <ul className={styles.nav_menu}>
          {routes.map(({ id, path, className, Label, onClick }) => (
            <li className={styles.nav_menu_item} key={id}>
              <NavLink
                to={path}
                className={styles.nav_menu_item_link}
                activeClassName={styles.nav_menu_item_link_active}
                onClick={onClick}
              >
                <span
                  className={cx(
                    styles.nav_menu_item_link_icon,
                    styles[`nav_menu_item_link_icon_${className}`],
                  )}
                ></span>{' '}
                <Label className={styles.nav_menu_item_link_label}/>
              </NavLink>
            </li>
          ))}
        </ul>

        <div className={styles.nav_menu_dropdown}>
          <div className={styles.nav_menu_dropdown_button} onClick={() => setDropdown(!dropdown)}>
            <Avatar fullName="runo saduwa" username="runo" />
          </div>
          {dropdown && (
            <ul className={styles.nav_menu_dropdown_menu}>
              <li className={styles.nav_menu_dropdown_menu_item}>
                <NavLink
                  to="/profile"
                  className={styles.nav_menu_dropdown_menu_item_link}
                  activeClassName={styles.nav_menu_dropdown_menu_item_link_active}
                  onClick={() => setDropdown(!dropdown)}
                >
                  My Profile
                </NavLink>
              </li>
              <li className={styles.nav_menu_dropdown_menu_item}>
                <NavLink
                  to="#"
                  className={styles.nav_menu_dropdown_menu_item_link}
                  onClick={handleSignOut}
                >
                  Logout
                </NavLink>
              </li>
            </ul>
          )}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
