import React, { useState } from 'react';
import PropTypes from 'prop-types';

import InputError from './InputError/InputError';

import styles from './form.module.scss';
import PhoneInputField from './PhoneInputField/PhoneInputField';
import Select from '../Select';

const FormField = ({
  className,
  name,
  children,
  defaultValue,
  onChange,
  label,
  hint,
  error,
  errorMessage,
  value,
  placeholder,
  register,
  isValid,
  country,
  options,
  ...props
}) => {
  const [showHint, setShowHint] = useState(false);
  const renderFormField = (type) => {
    switch (type) {
      case 'select':
        return (
          <Select
            options={options}
            onChange={onChange}
            className={className}
            value={value}
            withBorder={true}
            placeholder={placeholder}
          />
        );
      case 'phone':
        return (
          <PhoneInputField
            value={value}
            onChange={onChange}
          />
        );
      default:
        return (
          <div className={styles.inputGroup}>
            <span className={styles.inputGroup_label}>{label}</span>
            <input
              ref={register}
              className={styles.inputGroup_input}
              placeholder={placeholder}
              onChange={onChange}
              onFocus={() => setShowHint(true)}
              onBlur={() => setShowHint(false)}
              defaultValue={defaultValue}
              name={name}
              type={props.type}
            />
          </div>
        );
    }
  };

  return (
    <div className={styles.formField_inputWrapper}>
      {error && <InputError>{errorMessage}</InputError>}
      {renderFormField(props.type)}
      {hint && showHint && <div className={styles.formField_inputWrapper_hint}>{hint}</div>}
    </div>
  );
};

FormField.defaultProps = {
  label: 'label',
  hint: '',
  error: '',
  errorMessage: '',
  defaultValue: '',
  value: '',
};

FormField.propTypes = {
  label: PropTypes.string,
  hint: PropTypes.string,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default FormField;
