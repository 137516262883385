import React from 'react';
import OtpInput from 'react-otp-input';
import styles from './otp-input.module.scss';
const CustomOtpInput = ({ otp, onChange, numInputs, separator }) => {
  return (
    <div className={styles.otpInput}>
      <OtpInput inputStyle={{fontSize: '16px', border: "1px solid #e8e8e8", borderRadius: "10px", height: "50px", width: "66.54px"}} containerStyle={{display: 'flex', justifyContent: 'space-between'}} value={otp} onChange={onChange} numInputs={numInputs} separator={separator} />
    </div>
  );
};
CustomOtpInput.defaultProps = {
  onChange: () => {},
  otp: '',
  numInputs: 4,
  separator: '',
};
export default CustomOtpInput;
