/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://okhrpuys4bhnzc7elv6jhwolgy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-s4xub2cphbaefdqkzzafu2cwfi",
    "Auth": {
        mandatorySignIn: false,
        region: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
        identityPoolId: "us-east-1:39a70ec7-bed6-44ee-a206-818988c6f2c9",
        userPoolWebClientId:
          process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    },
    "Storage": {
        "AWSS3": {
            "bucket": "runobucket",
            "region": "us-east-1",
        }
    }
};


export default awsmobile;
