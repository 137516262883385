// WHY ?, for some unknown reason, very complex svgs dont render when completely when exported as ReactComponents, so we do this so that we can add the svgs as images
const Illustrations = {
  howItWorks: require('./how-it-works.svg'),
  howItWorksMobile: require('./how-it-works-mobile.svg'),
  platform: require('./listing-illustration.svg'),
  signupIllustration: require('./signup.svg'),
  greenCheckIllustration: require('./green-check.svg'),
  createListingIllustration: require('./create-listing.svg'),
  chateUiIllustration: require('./chatui.svg'),
};

export default Illustrations;
