import React from 'react';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';

import { connect } from 'react-redux';

import {
  SignupPage,
  LoginPage,
  AppHomePage,
  ChatPage,
  NotificationsPage,
  ProfilePage,
  ForgotPasswordPage,
} from 'src/containers/';

import { PublicRoute, PrivateRoute } from 'src/ui/Auxilliary';

import {
  linkToAboutPage,
  linkToAppHomePage,
  linkToForgotPasswordPage,
  linkToHelpPage,
  linkToHomePage,
  linkToHowItWorksPage,
  linkToMessagesPage,
  linkToNotificationsPage,
  linkToProfilePage,
  linkToSignInPage,
  linkToSignUpPage,
} from 'src/data/pageRoutes';

import { LandingPage, HowItWorksPage, AboutPage, HelpPage } from 'src/static-pages';

const AppRouter = ({ isLoggedIn }) => {
  return (
    <Router>
      <Switch>
        <PublicRoute
          isAuthenticated={isLoggedIn}
          restricted={true}
          exact
          path={linkToHomePage}
          component={LandingPage}
        />
        <PublicRoute
          isAuthenticated={isLoggedIn}
          restricted={true}
          exact
          path={linkToAboutPage}
          component={AboutPage}
        />
        <PublicRoute
          isAuthenticated={isLoggedIn}
          restricted={true}
          exact
          path={linkToHowItWorksPage}
          component={HowItWorksPage}
        />
        <PublicRoute
          isAuthenticated={isLoggedIn}
          restricted={true}
          exact
          path={linkToHelpPage}
          component={HelpPage}
        />

        <PublicRoute
          isAuthenticated={isLoggedIn}
          restricted={true}
          exact
          path={linkToSignInPage}
          component={LoginPage}
        />
        <PublicRoute
          isAuthenticated={isLoggedIn}
          restricted={true}
          exact
          path={linkToSignUpPage}
          component={SignupPage}
        />
        <PublicRoute
          isAuthenticated={isLoggedIn}
          restricted={true}
          exact
          path={linkToForgotPasswordPage}
          component={ForgotPasswordPage}
        />

        <PrivateRoute
          isAuthenticated={isLoggedIn}
          exact
          path={linkToAppHomePage}
          component={AppHomePage}
        />
        <PrivateRoute
          isAuthenticated={isLoggedIn}
          exact
          path={linkToMessagesPage}
          component={ChatPage}
        />
        <PrivateRoute
          isAuthenticated={isLoggedIn}
          exact
          path={linkToNotificationsPage}
          component={NotificationsPage}
        />
        <PrivateRoute
          isAuthenticated={isLoggedIn}
          exact
          path={linkToProfilePage}
          component={ProfilePage}
        />
        <Redirect from="/" to={linkToSignInPage} />
      </Switch>
    </Router>
  );
};

const mapStateToProps = (state) => ({
  // isLoggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
