import React from 'react';
import Avatar from '../Avatar';

import styles from './conversation-button.module.scss';
import cx from 'classnames';

const ConversationButton = ({ userHandle, firstName, lastName, newMessage, onClick, isActive }) => {
  return (
    <button
      className={cx(styles.conversationButton, {
        [styles.conversationButton_isActive]: isActive,
      })}
      onClick={onClick}
    >
      <Avatar firstName={firstName} lastName={lastName} username={userHandle} />
      {Boolean(newMessage) && <div className={styles.conversationButton_newMessage}><p>{newMessage > 10 ? '10+' : newMessage}</p></div>}
    </button>
  );
};

ConversationButton.defaultProps = {
  firstName: 'runo',
  lastName: 'saduwa',
  userHandle: '@runo',
  newMessage: true,
  onClick: () => {},
  isActive: false,
};
export default ConversationButton;
