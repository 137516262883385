import React from 'react';

import './HowItWorks.css';

import { Link } from 'react-router-dom';

import Illustrations from 'src/assets/svgs/illustrations';

const HowItWorks = () => {
  return (
    <section className="howItWorks__container">
      <header className="howItWorks__header">
        <div className="page-introduction-title">
          <div>
              <h1>how Troperial works</h1>
              <p>The quick, secure and fairer way to exchange currencies across borders.</p>
              <Link className="howItWorks_signup--btn" to="/signup">
                Create an account
              </Link>
          </div>
        </div>
      </header>

      <main className="howItWorks__info-section">
        {/* desktop view !! */}
        <section className="howItWorks__desktop-images">

            <div className="card-1 platform-card">
              <img src={Illustrations.signupIllustration} alt="sign up ui" />
            </div>
            <div className="card-2 platform-card">
              <img src={Illustrations.createListingIllustration} alt="trade form" />
            </div>
            <div className="card-3 platform-card">
              <img src={Illustrations.chateUiIllustration} alt="chat platform" />
            </div>
        </section>
        <section className="app_guide-section">
          <ul className="timeline">
            <li>
              <div className="item">
                <span className="timeline-icon green-check">
                  <img src={Illustrations.greenCheckIllustration} alt="timeline illustration" />
                </span>
                <div className="content">
                    <div>
                      <h1>create account</h1>
                      <p>
                        Create an account with your name location and
                        phone number for two factor verification.
                      </p>
                    </div>
                </div>
              </div>
            </li>
            <li>
              <div className="item">
                <span className="timeline-icon green-check">
                  <img src={Illustrations.greenCheckIllustration} alt="timeline illustration" />
                </span>
                <div className="content">
                    <div>
                      <h1>verify your identity</h1>
                      <p>
                        Just making sure you're neither a bot nor a
                        wanted person. All our Traders have to be
                        verified professionals ready to connect with
                        others.
                      </p>
                      <div className="card-1 platform-card">
                        <img src={Illustrations.signupIllustration} alt="sign up ui" />
                      </div>
                    </div>
                </div>
              </div>
            </li>
            <li>
              <div className="item">
                <span className="timeline-icon ellipse"></span>
                <div className="content">
                    <div>
                      <h1>
                        Select the currency you have &amp; would like
                        to trade
                      </h1>
                      <p>
                        Time to trade <br/>
                        Let’s trade <br/>
                         Ready to trade?
                      </p>
                    </div>
                </div>
              </div>
            </li>
            <li>
              <div className="item">
                <span className="timeline-icon ellipse"></span>
                <div className="content">
                    <div>
                      <h1>trade goes on FX Listings.</h1>
                      <p>
                        The first thing you need to do is to create a
                        Troperial account with your name, email, and
                        location. For Added security we also require a
                        phone number for two-factor authentication.
                      </p>
                      <div className="card-2 platform-card">
                        <img src={Illustrations.createListingIllustration} alt="trade form" />
                      </div>
                    </div>
                </div>
              </div>
            </li>
            <li>
              <div className="item">
                <span className="timeline-icon ellipse"></span>
                  <div className="content">
                    <h1>we match you with a trusted trader</h1>
                    <p>
                      The first thing you need to do is to create a
                      Troperial account with your name, email, and
                      location. For Added security we also require a
                      phone number for two-factor authentication.
                    </p>
                  </div>
              </div>
            </li>
            <li>
              <div className="item">
                <span className="timeline-icon ellipse"></span>
                  <div className="content">
                    <h1>negotiate exchange rate and Trade.</h1>
                    <p>
                      The first thing you need to do is to create a
                      Troperial account with your name, email, and
                      location. For Added security we also require a
                      phone number for two-factor authentication.
                    </p>
                    <div className="card-3 platform-card">
                      <img src={Illustrations.chateUiIllustration} alt="chat platform" />
                    </div>
                  </div>
              </div>
            </li>
          </ul>
        </section>
      </main>
    </section>
  );
};

export default HowItWorks;
