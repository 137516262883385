import addNotification from 'react-push-notification';

export const notifySuccess = (title = `🎉🎉🎉`, message = "", options = {}) => addNotification({
    title,
    subtitle: null,
    message,
    duration: 10000,
    theme: 'light',
    native: false
});

export const notifyError = (message, options = {}) =>
addNotification({
  title: `Error!`,
  subtitle: null,
  message: `${message}`,
  theme: 'red',
  native: false
});
