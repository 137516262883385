import React, {useState} from 'react';
import { createConversation, getUserAndConversations, setCurrentConversationDetails, updateConversations } from 'src/redux/modules/chat';
import {connect} from 'react-redux';

import {useHistory} from 'react-router-dom'
import { setMessagesView } from 'src/redux/modules/app';



let conversation = {
  exist: false,
  convoLinkConversationId: "",
  convoLinkUserId: "",
  messages: [],
};

const ListingsPage = ({updateConversations,getUserAndConversations, createConversation, username, setCurrentConversationDetails }) => {
  const history = useHistory()
  const [user2, setUser2] = useState("");

  const getUserAndConversationsCb = (result,user1, user2) => {
    result.some((item) => {
      if(item.conversation.name.includes(user2)){
        console.log(item)
        console.log(`conversation with ${user2} exists`)
        return conversation = {
          exist: true,
          convoLinkConversationId: item.conversation.id,
          messages: item.conversation.messages.items
        }
      }
    });

    if(conversation.exist !== true) {

      return createConversation(user1, user2, (convoLinkConversationId, messages, convo) => {
      updateConversations(convo)
      setCurrentConversationDetails({convoLinkConversationId:convoLinkConversationId, convoLinkUserId: user2, messages })
       getUserAndConversations('default',user1, (result) => history.push(`/messages`))
       
      });
    } else {
      setCurrentConversationDetails({convoLinkConversationId:conversation.convoLinkConversationId, convoLinkUserId: user2, messages: conversation.messages })
      history.push(`/messages`)
    }

  }

  const handleCreateConvo = () => {
    console.log(user2, username);
    //createConversation('test', 'test2', (id) => console.log('conversation created successfully', id))
   getUserAndConversations('default',username, (result) => getUserAndConversationsCb(result, username, user2))
  
  }








  return <div style={{paddingTop: '100px'}}>
  
      <label>enter username</label>
      <input onChange={(e) => setUser2(e.target.value)} value={user2} name="username"/>
      <button onClick={handleCreateConvo}>create conversation</button>
  </div>;
};

const mapStateToProps = (state) => ({
  username: state.auth.loggedInUserInfo['custom:userName']
});

const mapDispatchToProps = {getUserAndConversations, createConversation, setCurrentConversationDetails, updateConversations};

export default connect(mapStateToProps, mapDispatchToProps)(ListingsPage);
