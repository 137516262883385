import React from 'react';
import styles from './label.module.scss';
import cx from 'classnames';

const Label = ({ title, subTitle, className }) => {
  return (
    <div className={cx(className, styles.label)}>
      {typeof title === 'function' ? title() : <h1 className={styles.label_title}>{title}</h1>}
      {typeof subTitle === 'function' ? subTitle() : <p className={styles.label_subtitle}>{subTitle}</p>}
    </div>
  );
};
Label.defaultProps = {
  title: 'title',
  subTitle: 'subtitle subtitle',
};
export default Label;
