import React, {useState, useEffect} from 'react';

import ConversationButton from 'src/ui/ConversationButton';

import { findItem } from 'src/helpers/utils';

import styles from './conversation-list.module.scss';

const ConversationList = (props) => {
  const {
    onChangeConversation,
    gettingUserAndConversations,
    userConversations,
    conversationId,
    username,
    unreadMessages
  } = props;
  const [localUnreadMessages, setLocalUnreadMessages]= useState([]);

  useEffect(() => {
      setLocalUnreadMessages(unreadMessages)
  }, [unreadMessages])

  const getIndividualUnreadMessages = (id) => localUnreadMessages.filter(msg => msg.messageConversationId === id);

  return (
    <div className={styles.conversationList}>
      <header className={styles.conversationList_header}>
        <h1 className={styles.conversationList_header_heading}>Conversations</h1>
      </header>
      <div className={styles.conversationList_list}>
        {gettingUserAndConversations ? (
          <h4>Loading...</h4>
        ) : (
          userConversations.filter((convo) => convo.members[0] === username || convo.messages.items.length > 0).map((convo) => (
            <ConversationButton
              newMessage={getIndividualUnreadMessages(convo?.id).length}
              key={convo?.id}
              userHandle={findItem(convo?.members, username)}
              onClick={() =>
                onChangeConversation(
                  convo?.id,
                  findItem(convo?.members, username),
                  convo?.messages.items,
                  {newMessagesLength: getIndividualUnreadMessages(convo?.id).length,
                   unreadMessages: getIndividualUnreadMessages(convo?.id),
                  }
                )
              }
              isActive={conversationId === convo.id ? true : false}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default ConversationList;
