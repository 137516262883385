/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";

import PropTypes from "prop-types";

import styles from "./Select.module.scss";
import { CaretDown } from "src/assets/svgs";

const Select = ({ value, options, renderOption, onChange, className, withBorder, selectedText, ...props }) => {
  const [state, setState] = useState({
    showOptions: false,
    selected: {},
  });

  const select = useRef(null);

  const handleShowOptions = (showOptions) => setState((state) => ({ ...state, showOptions }));

  const getValueText = () => {
    if (selectedText) return selectedText;
    if (!(Object.keys(state.selected).length === 0 && state.selected.constructor === Object)) {
      return state.selected.label;
    }

    const valueOption = options.find((item) => item.value === value);
    return valueOption ? valueOption.value : (props.placeholder ? props.placeholder :"Select");
  };

  const handleOptionSelect = (e, selected) => {
    e.stopPropagation();
    setState((state) => ({
      ...state,
      selected,
      showOptions: false,
    }));
    onChange(selected);
  };

  useEffect(() => {
    const el = select.current;
    const eventHandler = (e) => {
      if (el && !el.contains(e.target)) {
        setState((state) => ({ ...state, showOptions: false }));
      }
    };
    if (typeof document !== `undefined`) {
      document.addEventListener("click", eventHandler);
    }

    return () => {
      if (typeof document !== `undefined`) {
        document.removeEventListener("click", eventHandler);
      }
    };
  }, [])

  return (
    // eslint-disable-next-line jsx-a11y/interactive-supports-focus
    <div role="button" onClick={() => handleShowOptions(!state.showOptions)} ref={select} className={cx(styles.select, className, {
      [styles.select__withBorder]: withBorder
    })} {...props}>
      <div className={styles.select__valueText}>{getValueText()}</div>
      <div
        className={cx(styles.select__icon, {
          [styles.select__icon__open]: state.showOptions,
        })}
      >
        <CaretDown/>
      </div>
      <div
        className={cx(styles.select__options, {
          [styles[`select__options_open`]]: state.showOptions,
        })}
      >
        <>
          {options.map((option, index) => {
            const optionProps = {
              ...option,
              key: `option-${index}`,
              onClick: (e) => {
                handleOptionSelect(e, option);
              },
              className: cx(styles.select__option, {
                [styles.select__option__active]: option.value === value,
                "active-option": option.value === value,
              }),
            };
            return renderOption ? (
              renderOption({
                ...optionProps,
                active: option.value === value,
              })
            ) : (
              <p {...optionProps}>{option.label}</p>
            );
          })}
        </>
      </div>
    </div>
  );
};

Select.propTypes = {
  multiple: PropTypes.bool,
  value: PropTypes.string,
  // options: { value: string; label: string }[];
  renderOption: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  selectedText: PropTypes.string,
};
export default Select;
