export const CreateUser = `mutation CreateUser($user: CreateUserInput!) {
    createUser(input: $user) {
        id
        username
        personId
    }
}`;

export const CreateConvoLink = `
  mutation CreateConvoLink($input: CreateConvoLinkInput!) {
    createConvoLink(input: $input) {
      id
      convoLinkUserId
      convoLinkConversationId
      conversation {
        id
        name
      }
    }
  }
`;

export const CreateConvo = `
  mutation CreateConvo($input: CreateConversationInput!) {
    createConvo(input: $input) {
      id
      name
      members
      messages {
        items {
          id
          authorId
          content
          type
          by
          have
          need
          preferredRate
          read
          messageConversationId
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const CreateMessage = `
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input:$input) {
      id
      content
      read
      type
      by
      have
      need
      preferredRate
      authorId
      messageConversationId
      createdAt
    }
  }
`;

export const UpdateConvo = `
  mutation UpdateConvo(
    $input: UpdateConversationInput!
  ) {
    updateConvo(input: $input) {
      id
      messages {
        items {
          id
          authorId
          content
          read
          messageConversationId
          createdAt
          updatedAt
        }
      }
      name
      members
      createdAt
      updatedAt
    }
  }
`;

export const UpdateMessage = `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
  ) {
    updateMessage(input: $input) {
      id
      authorId
      content
      read
      messageConversationId
      createdAt
      updatedAt
    }
  }
`;
