

/******* ABOUT PAGE CONSTANTS / CONTENT ******/
export const ABOUT_PAGE_HEADING = "To build a better and fairer way of transferring money accross the globe.";
export const ABOUT_PAGE_VISION = `"A comprehensive financial solution system to ease people's access to their money."`
export const TEAM_LIST = [
  {
    id: 1,
    name: "Bosun Gidigbi",
    image: "",
    role: "Technical Manager & Co-founder",
    desc: `
    <p class="teamMate_desc">Bosun graduated top of his undergraduate Computer Science class at Obafemi Awolowo University, Nigeria. He has over twelve years of experience in software development, and is currently a VP of Technology in a leading US financial institution.</p>

    <p class="teamMate_desc">Bosun specializes in—and has brought these skills to Troperial— Software Design and Architecture, Database Integration, Messaging Integration, Data Streaming, and Amazon Web Services. He hopes to build a company where people feel safe to connect and complete secure transactions.</p>

   <p class="teamMate_desc">At Troperial, he leads the engineering and technology team with a special focus on data acquisition. When not working, Bosun is playing and winning at FIFA. He takes his yearly breaks travelling and discovering different parts of the world and is known for taking the most memorable pictures. Precisely, he’s the type of person we’d refer to as cool people.</p>
    `
  },
  {
    id: 2,
    name: "Olu Akinbayo",
    image: "",
    role: "Product Manager",
    desc: `
    <p class="teamMate_desc">Olu is an Engineer who has built a career from one of the leading telcos in Africa to a leading Technology company in the US. Prior to founding Troperial, Olu worked in a systems multinational company —Cisco, as a senior network engineer.</p>

    <p class="teamMate_desc">With over ten years of engineering experience, the graduate of Covenant University and University of Houston has built a career as an Engineer, and has worked in several teams to achieve a common goal: creating human centered products. </p>

   <p class="teamMate_desc">He currently leads operations and product management at Troperial. When not building, he’s reading, listening to music—thanks to his eclectic taste— and thinking. Just say “Corgito Ergo Sum” and he’ll smile at you. Olu is passionate about people, technology, and simplifying complex processes.</p>
    `
  },
]
/******* END OF ABOUT PAGE CONSTANTS / CONTENT ******/

/******* FAQ **************************/
export const FAQ_LIST = [
  {
    id: 1,
    title: "What is Troperial?",
    content: "Troperial is a comprehensive financial solution system to ease people's access to their money. we offer an ever-evolving payment experience designed to foster quicker and fairer ways to exchange currencies, and the good part is, it's all at your prefered rate."
  },
]

/******* END OF FAQ **************************/

/** FOOTER */
export const FOOTER_MESSAGE =
  'Get the best exchange rates from verified persons across the world.';
