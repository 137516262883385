/*
 * User registration validation error messages
 */

export const REQUIRED_EMAIL_ERROR = "Email is required";
export const REQUIRED_USERNAME_ERROR = "Username is required";
export const REQUIRED_PASSWORD_ERROR = "Password is required";
export const REQUIRED_FIRST_NAME_ERROR = "First name is required";
export const REQUIRED_LAST_NAME_ERROR = "Last name is required";
export const REQUIRED_PHONE_ERROR = "Phone number is required";

export const VALID_EMAIL_ERROR = "Invalid email";
export const VALID_PHONE_ERROR = "Invalid phone number";
export const VALID_USERNAME_ERROR = "username should be between 6 and 8 characters";
export const VALID_PASSWORD_ERROR = "Password should be between 6 and 32 characters";
export const VALID_FIRST_NAME_ERROR = "first name should consist of only alphabets and must be between 2 and 32 characters";
export const VALID_LAST_NAME_ERROR = "last name should consist of only alphabets and must be between 2 and 32 characters";
