import React from 'react';

import Label from 'src/ui/Label';
import Button from 'src/ui/Button';
import { FormField } from 'src/ui/Form';

import { validation } from 'src/helpers/validation';

import styles from './verify-phone.module.scss';

const VerifyPhoneForm = ({ onSubmit, setVerificationStep }) => {

 const handleSubmit = (e) => {
   e.preventDefault();
   onSubmit()
 }
  return (
    <div className={styles.verifyPhoneForm}>
      <Label
        title={() => <h1 className={styles.verifyPhoneForm_heading}>Verify your Phone number</h1>}
        subTitle="Enter your mobile number, a one-time OTP will be sent to you(via SMS text message)."
      />
      <form className={styles.verifyPhoneForm_form} onSubmit={handleSubmit}>
        <FormField
          onChange={(value, data) => console.log(value, data)}
          label="Phone"
          placeholder="+234 XXXX XXXX XXX"
          type="phone"
          name="phone"
          validation={validation.phone}
        />
        <Button variant="themed" block>
          Send OTP
        </Button>
      </form>
      <Button
        className={styles.verifyPhoneForm_prevBtn}
        block
        variant="none"
        onClick={() => setVerificationStep(1)}
      >
        Previous step
      </Button>
    </div>
  );
};

export default VerifyPhoneForm;
