import React, { useState, useEffect} from 'react';
import { Link, NavLink } from 'react-router-dom';

import './public-nav.css';

import logo from '../../../assets/svgs/icons/Logo.svg';

import {
  linkToAboutPage,
  linkToHelpPage,
  linkToHomePage,
  linkToHowItWorksPage,
  linkToSignInPage,
  linkToSignUpPage,
} from '../../../data/pageRoutes';

import { Hamburger } from 'src/assets/svgs';

const NavBar = () => {
  const [open, setOpen] = useState(false);
  const [activeClass, setActiveClass] = useState(false);
  const toggleMenu = () => setOpen(!open);
  const closeMobileMenu = () => setOpen(false);

  useEffect(()=> {
    window.addEventListener('scroll', () => {
      let activeClass = 'navbar-bg';
      if(window.scrollY === 0){
          activeClass = 'top';
      }
      setActiveClass(activeClass);
   });

  }, [])
  return (
    <nav className={`navbar ${activeClass}`}>
      <NavLink to={linkToHomePage} className="navbar-logo">
        <img className="logo" src={logo} alt="Troperial" />
      </NavLink>

      <div onClick={toggleMenu} className="menu-icon">
        {!open ? <Hamburger/>: <Hamburger/>}
      </div>

      <ul className={open ? 'nav-menu active' : 'nav-menu'}>
        <li className="nav-item">
          <NavLink
            onClick={closeMobileMenu}
            to={linkToHowItWorksPage}
            activeClassName="active"
            className="nav-links"
          >
            How it works
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            onClick={closeMobileMenu}
            to={linkToHelpPage}
            activeClassName="active"
            className="nav-links"
          >
            Help
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            onClick={closeMobileMenu}
            to={linkToAboutPage}
            activeClassName="active"
            className="nav-links"
          >
            About
          </NavLink>
        </li>
        {/* <li className="nav-item">
          <Link
            to={linkToSignInPage}
            onClick={closeMobileMenu}
            className="SignInPage_link nav-links-mobile"
          >
            Sign In
          </Link>
        </li>
        <li className="nav-item lastLink">
          <Link
            to={linkToSignUpPage}
            onClick={closeMobileMenu}
            className="SignUpPage_link nav-links-mobile"
          >
            Create an account
          </Link>
        </li> */}
      </ul>
{/* 
      <div className="NavAuth">
        <Link to={linkToSignInPage} className="SignInPage_link">
          Sign In
        </Link>
        <Link to={linkToSignUpPage} className="SignUpPage_link">
          Create an account
        </Link>
      </div> */}
    </nav>
  );
};

export default NavBar;