import React from 'react';

import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import Signup from 'src/components/Auth/Signup';
import AuthLayout from 'src/layouts/auth-layout';

import { createUser } from 'src/redux/modules/auth';

import { linkToSignInPage } from 'src/data/pageRoutes';

const SignupPage = ({ createUser, signingUp }) => {
  const location = useHistory();

  // TODO: Change signUpSuccessCb function to display the banner that tells user to verify account
  const signUpSuccessCb = () => location.push(linkToSignInPage);

  const handleSignUp = (data) => {
    const input = {
      username: data.email,
      password: data.password,
      attributes: {
        email: data.email,
      },
    };
    createUser(input, () => signUpSuccessCb());
  };

  return (
    <AuthLayout>
      <Signup onSubmit={handleSignUp} signingUp={signingUp} />
    </AuthLayout>
  );
};

const mapStateToProps = (state) => ({
  signingUp: state.auth.signingUp,
});

const mapDispatchToProps = { createUser };

export default connect(mapStateToProps, mapDispatchToProps)(SignupPage);
