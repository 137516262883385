import React from 'react';

import Button from 'src/ui/Button';

import Illustrations from 'src/assets/svgs/illustrations';

import styles from './section-three.module.scss';
import { linkToHowItWorksPage } from 'src/data/pageRoutes';

const SectionThree = (props) => {
  return (
    <section className={styles.sectionThree}>
      <header className={styles.sectionThree_header}>
        <h1 className={styles.sectionThree_header_title}>How Troperial works</h1>
        <p className={styles.sectionThree_header_subtitle}>
          We connect people to transact foreign currencies at a fair rate.
        </p>
        <Button variant="themedLink" to={linkToHowItWorksPage} className={styles.sectionThree_header_link}>
          Learn more about how it works
        </Button>
      </header>

      <div className={styles.sectionThree_illustrationArea}>
        <img
          className={styles.sectionThree_illustrationArea_desktopIllustration}
          src={Illustrations.howItWorks}
          alt="description"
        />
        <img
          className={styles.sectionThree_illustrationArea_mobileIllustration}
          src={Illustrations.howItWorksMobile}
          alt="description-mobile"
        />
      </div>

      <div className={styles.sectionThree_body}>
        <div className={styles.sectionThree_body_card}>
          <h1 className={styles.sectionThree_body_card_title}>Me</h1>
          <p className={styles.sectionThree_body_card_subtitle}>
          List the currency I have, destination currency I need and my preferred exchange rate.
          </p>
        </div>
        <div className={styles.sectionThree_body_card}>
          <h1 className={styles.sectionThree_body_card_title}>Trading Floor</h1>
          <p className={styles.sectionThree_body_card_subtitle}>
          We connect verified persons, provide access to our trading floor, and create private space for negotiations.
          </p>
        </div>
        <div className={styles.sectionThree_body_card}>
          <h1 className={styles.sectionThree_body_card_title}>You</h1>
          <p className={styles.sectionThree_body_card_subtitle}>
          List the currency you need, source currency you have and your preferred exchange rate.
          </p>
        </div>
      </div>
    </section>
  );
};

export default SectionThree;
