import React from 'react';
import Button from 'src/ui/Button';
import { FormField } from 'src/ui/Form';

import { useForm } from 'react-hook-form';
import { validation } from 'src/helpers/validation';

const WaitList = ({ onSubmit, onSelect, selected, addingPersonToWaitlist }) => {
  const { handleSubmit, register, errors } = useForm({ reValidateMode: 'onChange' });
  const discoveredUsFromOptions = [
    {
      label: 'Social Media',
      value: 'Social Media',
    },
    {
      label: 'A Friend',
      value: 'A Friend',
    },
  ];
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormField
          register={register(validation.firstname)}
          onChange={(e) => e.target.value.trim()}
          label="First Name"
          error={errors.firstname}
          errorMessage={errors?.firstname?.message}
          placeholder="First Name"
          name="firstName"
        />
        <FormField
          register={register(validation.lastname)}
          onChange={(e) => e.target.value.trim()}
          label="Last Name"
          error={errors.lastname}
          errorMessage={errors?.lastname?.message}
          placeholder="Last Name"
          name="lastName"
        />
        <FormField
          register={register(validation.email)}
          onChange={(e) => e.target.value.trim()}
          label="Email Address"
          error={errors.email}
          errorMessage={errors?.email?.message}
          placeholder="you@example.com"
          name="emailAddress"
        />
        <FormField
          options={discoveredUsFromOptions}
          onChange={onSelect}
          value={selected}
          placeholder="Discovered us from"
          type="select"
        />
        <Button variant="themed" block loading={addingPersonToWaitlist}>
          Join the waitlist
        </Button>
      </form>
    </div>
  );
};

export default WaitList;
