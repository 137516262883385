import React from 'react';
import styles from './account-summary-card.module.scss';
import cx from 'classnames';

const AccountSummaryCard = ({ title, number, className, variant }) => {
  return (
    <div className={cx(className, styles.summary)}>
      <span className={styles.summary_title}>{title}</span>
      <h1
        className={cx(styles.summary_number, {
          [styles[`summary_number_${variant}`]]: variant,
        })}
      >
        {number} <p className={styles.summary_sub}>{title}</p>
      </h1>
    </div>
  );
};

AccountSummaryCard.defaultProps = {
  title: 'Listings Posted',
  number: 100,
  variant: 'yellow'
};

export default AccountSummaryCard;
