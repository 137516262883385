import React from 'react';
import Button from 'src/ui/Button';
import Label from 'src/ui/Label';

import styles from './forgot-password.module.scss';
import ResetPasswordForm from './ResetPasswordForm/ResetPasswordForm';
import SendResetCode from './SendResetCode/SendResetCode';

const ForgotPassword = ({ handleSendEmailCode, handleResetPassword, view, setView, location }) => {
  const forgotPasswordView = (view) => {
    switch (view) {
      case 'SEND_RESET_CODE':
        return <SendResetCode onSubmit={handleSendEmailCode} location={location} />;
      case 'RESET_PASSWORD_FORM':
        return <ResetPasswordForm onSubmit={handleResetPassword} location={location} />;
      case 'RESET_CODE_SENT':
        return Notification(
          'Check your mail!',
          'Copy the code in the mail and come back to complete your password reset',
          'Next',
          () => setView('RESET_PASSWORD_FORM'),
        );
      case 'PASSWORD_RESET_SUCCESS':
        return Notification(
          'Your password’s been reset!',
          'Now you can get back into your account to make the trades you want.',
          'Get back in',
          () => location.push('/signin'),
        );
      default:
        return <SendResetCode onSubmit={handleSendEmailCode} />;
    }
  };
  const Notification = (title, subTitle, btnText, onClick) => (
    <div className={styles.forgotPassword_notify}>
      <Label className={styles.forgotPassword_notify_label} title={title} subTitle={subTitle} />
      <Button variant="themed" block onClick={onClick}>
        {btnText}
      </Button>
    </div>
  );
  return <div className={styles.forgotPassword}>{forgotPasswordView(view)}</div>;
};

ForgotPassword.defaultProps = {
  view: 'SEND_RESET_CODE',
};
export default ForgotPassword;
