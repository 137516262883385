import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import {connect} from 'react-redux';

import AppNavBar from '../../components/AppNavbar';

import styles from './app-layout.module.scss';
import { signOut } from 'src/redux/modules/auth';

const AppLayout = ({ className, children, hideAside, signOut, unreadMessagesLength}) => {
  const [localUnreadMessagesLength, setLocalUnreadMessagesLength] = useState(null)

  useEffect(() => {
    setLocalUnreadMessagesLength(unreadMessagesLength)
  }, [unreadMessagesLength]);
  return (
    <section className={cx(className, styles.AppLayout)}>
      <AppNavBar signOut={signOut} unreadMessagesLength={localUnreadMessagesLength} setunreadMessagesLength={setLocalUnreadMessagesLength}/>
      <main className={styles.AppLayout_body}>
        {!hideAside && (
              <div className={styles.AppLayout_body_aside}>
               
              </div>
            )}
        <div className={styles.AppLayout_body_main}>{children}</div>
      </main>
    </section>
  );
};

AppLayout.defaultProps = {
  hideAside: false,
};

const mapStateToProps = (state) => ({
  unreadMessagesLength: state.chat.unreadMessagesLength
});

const mapDispatchToProps = { signOut };

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
