import React from 'react';
import { AppLogo, AuthLayoutIllustration1 } from 'src/assets/svgs';

import styles from './auth-layout.module.scss';

const AuthLayout = ({ children, illustration }) => {
  return (
    <div className={styles.authLayout}>
      <div className={styles.authLayout_aside}>
        <div className={styles.authLayout_aside_header}>
          <AppLogo />
          <h2 className={styles.authLayout_aside_header_text}>
            Exchange Currencies <span role="img" aria-label="money">💰</span> <br /> at Your Preferred Rate
          </h2>
        </div>

        <div className={styles.authLayout_aside_illustration}>{illustration()}</div>
      </div>
      <main className={styles.authLayout_main}>
        <div className={styles.authLayout_main_wrapper}>{children}</div>
      </main>
    </div>
  );
};

AuthLayout.defaultProps = {
  illustration: () => <AuthLayoutIllustration1 />,
};
export default AuthLayout;
