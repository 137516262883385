import {
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  GET_AUTHENTICATED_USER_REQUEST,
  GET_AUTHENTICATED_USER_SUCCESS,
  GET_AUTHENTICATED_USER_FAILURE,
  SIGN_OUT_REQUEST,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FAILURE,
  GET_LOGGED_IN_USER_INFO_REQUEST,
  GET_LOGGED_IN_USER_INFO_SUCCESS,
  GET_LOGGED_IN_USER_INFO_FAILURE,
} from './types';

import asyncWrapper from 'src/helpers/asyncWrapper';
import { handleError } from 'src/helpers/errorHandlers';

/**
 *
 * @param {object} userCredentials
 * @param {function} successCb Success callback
 */
export const createUser = (userCredentials, successCb) => ({
  types: [SIGN_UP_REQUEST, SIGN_UP_SUCCESS, SIGN_UP_FAILURE],
  promise: async ({ accountsApiClient, personsApiClient, transactionsApiClient, auth }) => {
    const [result, err] = await asyncWrapper(auth.signUp(userCredentials));

    if (err) {
      handleError(err);
    }

    if (result) {
      successCb();
      return result.user;
    }
  },
});

/**
 *
 * @param {object} param0
 * @param {function} successCb Success callback
 */
export const signInUser = ({ email, password }, successCb) => ({
  types: [SIGN_IN_REQUEST, SIGN_IN_SUCCESS, SIGN_IN_FAILURE],
  promise: async ({ accountsApiClient, personsApiClient, transactionsApiClient, auth }) => {
    const [result, err] = await asyncWrapper(auth.signIn(email, password));

    if (err) {
      console.log(err);
      handleError(err);
    }

    if (result) {
      successCb(result?.attributes);
      return result?.attributes;
    }
  },
});

/**
 *
 * @param {function} successCb Success callback
 */
export const getAuthenticatedUser = (successCb) => ({
  types: [
    GET_AUTHENTICATED_USER_REQUEST,
    GET_AUTHENTICATED_USER_SUCCESS,
    GET_AUTHENTICATED_USER_FAILURE,
  ],
  promise: async ({ accountsApiClient, personsApiClient, transactionsApiClient, auth }) => {
    const [result, err] = await asyncWrapper(auth.currentSession());

    if (err) {
      throw err;
    }

    if (result) {
      successCb();
      console.log(result);
    }
  },
});

/**
 *
 * @param {function} successCb Success callback
 */
export const getLoggedInUserInfo = (successCb) => ({
  types: [
    GET_LOGGED_IN_USER_INFO_REQUEST,
    GET_LOGGED_IN_USER_INFO_SUCCESS,
    GET_LOGGED_IN_USER_INFO_FAILURE,
  ],
  promise: async ({ accountsApiClient, personsApiClient, transactionsApiClient, auth }) => {
    const [result, err] = await asyncWrapper(auth.currentUserInfo());

    if (err) {
      console.log(err);
      handleError(err);
    }

    if (result) {
      successCb();
      return result?.attributes;
    }
  },
});

/**
 *
 * @param {function} successCb Success callback
 */
export const signOut = (successCb) => ({
  types: [SIGN_OUT_REQUEST, SIGN_OUT_SUCCESS, SIGN_OUT_FAILURE],
  promise: async ({ accountsApiClient, personsApiClient, transactionsApiClient, auth }) => {
    const [result, err] = await asyncWrapper(auth.signOut());

    if (err) {
      console.log(err);
      handleError(err);
    }

    if (result) {
      successCb();
      console.log(result);
      return result.data.data;
    }
  },
});
