import React, { useState, useEffect } from 'react';
import ScrollableFeed from 'react-scrollable-feed';

import Avatar from 'src/ui/Avatar';

import { ArrowLeftIcon } from '../../../assets/svgs';

import styles from './chat-view.module.scss';
import ChatInput from './ChatInput';
import { ChatFeedWrapper } from 'src/ui/Auxilliary';
import {TextChatBubble} from 'src/ui/ChatBubbles';

const ChatView = (props) => {
  const {
    setMessagesFeedInView,
    creatingMessage,
    onSubmit,
    goToUserChatProfile,
    goToConversationList,
    convoLinkUserId,
    gettingMessages,
    messages,
    username,
  } = props;

  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(message);
    setMessage('');
  };

  useEffect(() => {
    setMessagesFeedInView(true);
    return () => setMessagesFeedInView(false);
  }, []);

  return (
    <ChatFeedWrapper className={styles.chatView}>
      <div className={styles.chatView_header}>
        <button className={styles.chatView_header_back} onClick={goToConversationList}>
          <ArrowLeftIcon />
        </button>
        <Avatar onClick={goToUserChatProfile} fullName="runo saduwa" username={convoLinkUserId} />
      </div>
      <ScrollableFeed forceScroll={true} className={styles.chatView_messagesFeed}>
        {gettingMessages === true ? (
          <p>loading...</p>
        ) : (
          messages.map((message) => {
            if (Boolean(message?.content)) {
              return (
                <TextChatBubble
                  username={username}
                  key={message?.id}
                  createdAt={message?.createdAt}
                  content={message?.content}
                  authorId={message?.authorId}
                />
              );
            } else {
              return null;
            }
          })
        )}
      </ScrollableFeed>

      <ChatInput
        creatingMessage={creatingMessage}
        handleSubmit={handleSubmit}
        convoLinkUserId={convoLinkUserId}
        message={message}
        setMessage={setMessage}
      />
    </ChatFeedWrapper>
  );
};

export default ChatView;
