import React from 'react';
import styles from './chat-view.module.scss';

import Button from 'src/ui/Button';
import Divider from 'src/ui/Divider';

import { BoltIcon, PaperPlaneIcon } from '../../../assets/svgs';

const ChatInput = ({ convoLinkUserId, handleSubmit, setMessage, message, creatingMessage}) => {
  return (
    <div className={styles.chatView_inputContainer}>
      <div className={styles.chatView_inputContainer_listingHolder}></div>
      <form className={styles.chatView_inputContainer_messageForm} onSubmit={handleSubmit}>
        <input
          placeholder={creatingMessage ? 'Sending...' : `Message @${convoLinkUserId}...`}
          className={styles.chatView_inputContainer_messageForm_input}
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          name="message"
          type="text"
          disabled={creatingMessage}
        />
        <button
          type="submit"
          disabled={!message || creatingMessage}
          className={styles.chatView_inputContainer_messageForm_sendBtn}
        >
          <span className={styles.chatView_inputContainer_messageForm_sendBtn_text}>send</span>
          <PaperPlaneIcon className={styles.chatView_inputContainer_messageForm_sendBtn_icon} />
        </button>
      </form>
      <Divider />
      <div className={styles.chatView_inputContainer_quickActions}>
        <Button
          variant="nothing"
          className={styles.chatView_inputContainer_quickActions_quickActionMainButton}
        >
          <BoltIcon
            className={styles.chatView_inputContainer_quickActions_quickActionMainButton_icon}
          />{' '}
          Quick Actions
        </Button>
        <Button
          className={styles.chatView_inputContainer_quickActions_quickActionButton}
          variant="themedClear"
        >
          Initiate Transactions
        </Button>
      </div>
    </div>
  );
};

export default ChatInput;
