export const ListUsers = `
    query ListUsers {
        listUsers {
            items {
                id
                username
                personId
                createdAt
            }
        }
    }
`;

export const GetUser = `
    query GetUser($id: ID!) {
        getUser(id: $id) {
            id
            username
            personId
        }
    }
`;

export const GetUserAndConversations = `
  query GetUserAndConversations($id:ID!) {
    getUser(id:$id) {
      id
      username
      conversations(limit: 1000) {
        items {
          id
          conversation {
            id
            name
            members
            createdAt
            updatedAt
            messages (limit: 1000) {
              items {
                id
                authorId
                content
                read
                type
                by
                have
                need
                preferredRate
                messageConversationId
                createdAt
              }
            }
          }
        }
      }
    }
  }
`;

export const GetConvo = `
  query GetConvo($id: ID!) {
    getConvo(id: $id) {
      id
      messages (limit: 1000){
        items {
          id
          authorId
          content
          read
          type
          by
          have
          need
          preferredRate
          messageConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      associated {
        items {
          id
          convoLinkUserId
          convoLinkConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      members
      createdAt
      updatedAt
    }
  }
`;
