import React from 'react';

import Hero from './Hero';
import SectionTwo from './SectionTwo';
import SectionThree from './SectionThree';
import SectionFour from './SectionFour';
import SectionFive from './SectionFive';

const Home = () => (
  <>
    <Hero />
    <SectionTwo />
    <SectionThree />
    <SectionFour />
    <SectionFive />
  </>
);

export default Home;
