import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { linkToSignInPage, linkToMessagesPage } from 'src/data/pageRoutes';
import AppLayout from 'src/layouts/app-layout';

const PrivateRoute = (props) => {
  const { component: Component, isAuthenticated, ...rest } = props;

  const render = (props) => {
    if (isAuthenticated === false) {
      return <Redirect to={linkToSignInPage} />;
    }
    return (
      <AppLayout hideAside={props.location.pathname === linkToMessagesPage ? true : false}>
        <Component {...props} />
      </AppLayout>
    );
  };

  return <Route {...rest} render={render} />;
};

export default PrivateRoute;
