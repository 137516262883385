import React, { useRef, useState } from 'react';

import { useForm } from 'react-hook-form';

import Label from 'src/ui/Label';
import Button from 'src/ui/Button';
import { FormField } from 'src/ui/Form';
import Meter from 'src/ui/Meter';

import { AppLogo } from 'src/assets/svgs';

import { validation } from 'src/helpers/validation';

import styles from './signup.module.scss';

const Signup = ({ onSubmit, signingUp }) => {
  const { register, handleSubmit, errors, watch } = useForm({ reValidateMode: 'onChange' });
  const [passwordTrack, setPasswordTrack] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const password = useRef({});

  password.current = watch('password', '');

  return (
    <div className={styles.signup}>
      <AppLogo />
      <Label
        title={() => (
          <h1 className={styles.signup_heading}>
            Create a <span>Troperial</span> account
          </h1>
        )}
        subTitle="Create an account to access to a quick, secure & to exchange currencies."
      />
      <form className={styles.signup_form} onSubmit={handleSubmit(onSubmit)}>
        <FormField
          register={register(validation.email)}
          onChange={(e) => e.target.value.trim()}
          label="Email"
          error={errors.email}
          errorMessage={errors?.email?.message}
          placeholder="bosun@gmail.com"
          name="email"
        />
        <FormField
          register={register(validation.password)}
          onChange={(e) => {
            e.target.value.trim();
            setPasswordTrack(e.target.value);
          }}
          label="Password"
          error={errors.password}
          errorMessage={errors?.password?.message}
          placeholder="Create a Password"
          hint="Password must be between 6 - 20 characters and must include atleast 1 Uppercase letter, 1 Lowercase letter, 1 numeric value and one special character."
          name="password"
          type="password"
        />
        <FormField
          onChange={(e) => e.target.value.trim()}
          label="Confirm Password"
          error={confirmPasswordError}
          errorMessage={confirmPasswordError}
          placeholder="Confirm Password"
          name="confirmPassword"
          type="password"
          register={register({
            required: true,
            validate: (value) => {
              if (value === password.current) {
                setConfirmPasswordError('');
              } else {
                setConfirmPasswordError('Password does not match');
              }
            },
          })}
        />
        <Meter value={passwordTrack} />
        <Button disabled={signingUp} loading={signingUp} variant="themed" block>
          Create account
        </Button>
      </form>

      <div className={styles.signup_linkSection}>
        <p className={styles.signup_linkSection_p}>
          Already got an account?{' '}
          <Button variant="themedLink" to="/login" className={styles.signup_linkSection_p_link}>
            Login
          </Button>
        </p>
      </div>
    </div>
  );
};

export default Signup;
