import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthLayoutIllustration2 } from 'src/assets/svgs';
import ForgotPassword from 'src/components/Auth/ForgotPassword';
import AuthLayout from 'src/layouts/auth-layout';

const ForgotPasswordPage = (props) => {
  const [view, setView] = useState('SEND_RESET_CODE');
  const location = useHistory();

  const handleResetPassword = (data) => {
    setView('PASSWORD_RESET_SUCCESS')
  }
  const handleSendEmailCode = (data) => {
    setView('RESET_CODE_SENT')
  }
  return (
    <AuthLayout illustration={() => <AuthLayoutIllustration2 />}>
      <ForgotPassword handleResetPassword={handleResetPassword} handleSendEmailCode={handleSendEmailCode} location={location} view={view} setView={setView} />
    </AuthLayout>
  );
};

export default ForgotPasswordPage;
