import {
  GET_USER_FROM_DYNAMODB_REQUEST,
  GET_USER_FROM_DYNAMODB_SUCCESS,
  GET_USER_FROM_DYNAMODB_FAILURE,
  ADD_USER_TO_DYNAMODB_REQUEST,
  ADD_USER_TO_DYNAMODB_SUCCESS,
  ADD_USER_TO_DYNAMODB_FAILURE,
  GET_USER_AND_CONVERSATIONS_REQUEST,
  GET_USER_AND_CONVERSATIONS_SUCCESS,
  GET_USER_AND_CONVERSATIONS_FAILURE,
  BG_GET_USER_AND_CONVERSATIONS_REQUEST,
  BG_GET_USER_AND_CONVERSATIONS_SUCCESS,
  BG_GET_USER_AND_CONVERSATIONS_FAILURE,
  CREATE_CONVERSATION_REQUEST,
  CREATE_CONVERSATION_SUCCESS,
  CREATE_CONVERSATION_FAILURE,
  UPDATE_CONVERSATION_REQUEST,
  UPDATE_CONVERSATION_SUCCESS,
  UPDATE_CONVERSATION_FAILURE,
  CREATE_MESSAGE_REQUEST,
  CREATE_MESSAGE_SUCCESS,
  CREATE_MESSAGE_FAILURE,
  UPDATE_MESSAGE_REQUEST, 
  UPDATE_MESSAGE_SUCCESS, 
  UPDATE_MESSAGE_FAILURE,
  GET_UNREAD_MESSAGES,
  SET_CURRENT_CONVERSATION_DETAILS,
  UPDATE_CONVERSATIONS,
  UPDATE_MESSAGES,
} from './types';

const initialState = {
  gettingUserFromDynamoDb: false,
  getUserFromDynamoDbError: '',
  userFromDynamoDb: {},

  addingUserToDynamoDb: false,
  addUserToDynamoDbError: '',

  updatingConversation: false,
  updateConversationError: '',
  updatedConversation: '',

  gettingUserAndConversations: false,
  conversationsLength: 0,
  unreadMessagesLength: 0,
  filteredUserConversations: [],
  userConversations: [],
  unreadMessages: [],
  getUserAndConversationsError: '',

  bgGettingUserAndConversations: false,
  bgGetUserAndConversationsError: '',

  creatingMessage: false,
  createMessageError: '',
  newMessage: {},

  updatingMessage: false,
  updateMessageError: '',
  updatedMessage: {},

  creatingConversation: false,
  newConversationId: '',
  createConversationError: '',

  currentConversationDetails: {
    convoLinkConversationId: '',
    convoLinkUserId: '',
    messages: []
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_MESSAGES:
      const {convoLinkConversationId, convoLinkUserId, messages} =state.currentConversationDetails;
      const updatedMessageIndex = messages.findIndex(msg => msg.id === action.payload.id);
      console.log(updatedMessageIndex);
      const updatedMessages = updatedMessageIndex < 0 ? [...messages, action.payload] : [...messages.slice(0, updatedMessageIndex), action.payload, ...messages.slice(updatedMessageIndex + 1)];
      return {
        ...state,
        updatedMessage: action.payload,
        currentConversationDetails: {convoLinkConversationId, convoLinkUserId, messages:updatedMessages} 
      };
    case UPDATE_CONVERSATIONS:
      const updatedConversations = [action.payload, ...state.userConversations]
      return {
        ...state,
        userConversations: updatedConversations.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
      };
    case GET_UNREAD_MESSAGES:
      const newUnreadMessages = [...state.unreadMessages, action.payload];
      const newUnreadMessagesLength =newUnreadMessages.length;
      return {
        ...state,
        unreadMessages: newUnreadMessages,
        unreadMessagesLength: newUnreadMessagesLength
      };
    case SET_CURRENT_CONVERSATION_DETAILS:
      return {
        ...state,
        currentConversationDetails: action.payload
      };
    case UPDATE_CONVERSATION_REQUEST:
      return {
        ...state,
        updatingConversation: true,
      };
    case UPDATE_CONVERSATION_SUCCESS:
      return {
        ...state,
        updatingConversation: false,
        updatedConversation: action.result,
      };
    case UPDATE_CONVERSATION_FAILURE:
      return {
        ...state,
        updatingConversation: false,
        updateConversationError: action.error,
      };
    case UPDATE_MESSAGE_REQUEST:
      return {
        ...state,
        updatingMessage: true,
      };
    case UPDATE_MESSAGE_SUCCESS:
      return {
        ...state,
        updatingMessage: false,
      };
    case UPDATE_MESSAGE_FAILURE:
      return {
        ...state,
        updatingMessage: false,
        updateMessageError: action.error,
      };
    case CREATE_MESSAGE_REQUEST:
      return {
        ...state,
        creatingMessage: true,
      };
    case CREATE_MESSAGE_SUCCESS:
      return {
        ...state,
        creatingMessage: false,
        newMessage: action.result
      };
    case CREATE_MESSAGE_FAILURE:
      return {
        ...state,
        creatingMessage: false,
        createMessageError: action.error,
      };
    case CREATE_CONVERSATION_REQUEST:
      return {
        ...state,
        creatingConversation: true,
      };
    case CREATE_CONVERSATION_SUCCESS:
      return {
        ...state,
        creatingConversation: false,
        newConversationId: action.result
      };
    case CREATE_CONVERSATION_FAILURE:
      return {
        ...state,
        creatingConversation: false,
        createConversationError: action.error,
      };
    case GET_USER_AND_CONVERSATIONS_REQUEST:
      return {
        ...state,
        gettingUserAndConversations: true,
      };
    case GET_USER_AND_CONVERSATIONS_SUCCESS:
      let unreadMessages = [], allMessages = [];
      action.result.conversations.forEach(item => allMessages.push(...item.conversation.messages.items));
      unreadMessages = allMessages.filter(message => message.read === false && message.authorId !== action.result.loggedInUser);
      const userConversations = action.result.conversations.map(item => item.conversation).sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
      return {
        ...state,
        gettingUserAndConversations: false,
        userConversations,
        unreadMessagesLength: unreadMessages.length,
        unreadMessages,
        currentConversationDetails: {
          convoLinkConversationId: userConversations[0].id,
          convoLinkUserId: userConversations[0].members.filter(member => member !== action.result.loggedInUser),
          messages: userConversations[0].messages.items,
        }
      };
    case GET_USER_AND_CONVERSATIONS_FAILURE:
      return {
        ...state,
        gettingUserAndConversations: false,
        getUserAndConversationsError: action.error,
      };
    case BG_GET_USER_AND_CONVERSATIONS_REQUEST:
      return {
        ...state,
        bgGettingUserAndConversations: true,
      };
    case BG_GET_USER_AND_CONVERSATIONS_SUCCESS:
      let bgUnreadMessages = [], bgAllMessages = [];
      action.result.conversations.forEach(item => bgAllMessages.push(...item.conversation.messages.items));
      bgUnreadMessages = bgAllMessages.filter(message => message.read === false && message.authorId !== action.result.loggedInUser);
      const bgUserConversations = action.result.conversations.map(item => item.conversation).sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
      return {
        ...state,
        bgGettingUserAndConversations: false,
        userConversations: bgUserConversations,
        unreadMessagesLength: bgUnreadMessages.length,
        unreadMessages: bgUnreadMessages,
      };
    case BG_GET_USER_AND_CONVERSATIONS_FAILURE:
      return {
        ...state,
        bgGettingUserAndConversations: false,
        bgGetUserAndConversationsError: action.error,
      };
    case ADD_USER_TO_DYNAMODB_REQUEST:
      return {
        ...state,
        addingUserToDynamoDb: true,
      };
    case ADD_USER_TO_DYNAMODB_SUCCESS:
      return {
        ...state,
        addingUserToDynamoDb: false,
      };
    case ADD_USER_TO_DYNAMODB_FAILURE:
      return {
        ...state,
        addingUserToDynamoDb: false,
        addUserToDynamoDbError: action.error,
      };
    case GET_USER_FROM_DYNAMODB_REQUEST:
      return {
        ...state,
        gettingUserFromDynamoDb: true,
      };
    case GET_USER_FROM_DYNAMODB_SUCCESS:
      return {
        ...state,
        gettingUserFromDynamoDb: false,
        userFromDynamoDb: action.result,
      };
    case GET_USER_FROM_DYNAMODB_FAILURE:
      return {
        ...state,
        gettingUserFromDynamoDb: false,
        getUserFromDynamoDbError: action.error,
      };
    default:
      return state;
  }
}
