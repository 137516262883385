import React from 'react';
import styles from './send-reset-code.module.scss';

import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import Label from 'src/ui/Label';
import Button from 'src/ui/Button';
import { FormField } from 'src/ui/Form';

import { AppLogo } from 'src/assets/svgs';

import { validation } from 'src/helpers/validation';

const SendResetCode = ({ onSubmit }) => {
  const location = useHistory();
  const { handleSubmit, register, errors } = useForm({ reValidateMode: 'onChange' });

  return (
    <div className={styles.sendResetCode}>
      <AppLogo onClick={() => location.push('/')} />
      <Label
        title={() => (
          <h1 className={styles.sendResetCode_heading}>
            Forgot <span>Password</span>
          </h1>
        )}
        subTitle="Enter your email to reset your password"
      />
      <form className={styles.sendResetCode_form} onSubmit={handleSubmit(onSubmit)}>
        <FormField
          register={register(validation.email)}
          onChange={(e) => e.target.value.trim()}
          label="Email"
          error={errors.email}
          errorMessage={errors?.email?.message}
          placeholder="bosun@gmail.com"
          name="email"
        />
        <Button variant="themed" block>
          Next
        </Button>
      </form>
      <div className={styles.sendResetCode_linkSection}>
        <p className={styles.sendResetCode_linkSection_p}>
          <Button variant="themedLink" to="/" className={styles.sendResetCode_linkSection_p_link}>
            Back to Sign In
          </Button>
        </p>
      </div>
    </div>
  );
};

export default SendResetCode;
