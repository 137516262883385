import React, {useEffect} from 'react';
import { useMediaQuery } from 'react-responsive';
import {connect} from 'react-redux';

// Components
import ChatView from 'src/components/Chat/ChatView';
import ConversationList from 'src/components/Chat/ConversationList';
import ChatUserProfile from 'src/components/Chat/ChatUserProfile';

// Actions
import { setMessagesFeedInView, setMessagesView } from 'src/redux/modules/app';
import { createMessage, setCurrentConversationDetails, updateMessage, getUserAndConversations, updateConversation } from 'src/redux/modules/chat';

// Utils
import { MEDIA_QUERY } from 'src/data';

// Styles
import styles from './chat-page.module.scss';


const ChatPage = ({setMessagesFeedInView, updateConversation, creatingMessage, unreadMessages, getUserAndConversations,currentConversationDetails,updateMessage,setCurrentConversationDetails , username, createMessage, gettingUserAndConversations, userConversations, setMessagesView, messagesView}) => {
  const { displayChatView, displayConversationList, displayUserChatProfile } = messagesView;
  const {convoLinkConversationId, convoLinkUserId, messages} = currentConversationDetails;

  const isDesktopOrLaptop = useMediaQuery(MEDIA_QUERY.isDesktopOrLaptop);
  
  const onChangeConversation = (id,name, messages, {newMessagesLength, unreadMessages}) => {
    setCurrentConversationDetails({convoLinkConversationId:id, convoLinkUserId: name, messages })
    if(newMessagesLength !== 0) unreadMessages.forEach((message) => updateMessage({id: message.id, read: true}, () => getUserAndConversations('background', username, () => console.log('updated'))))
    
    isDesktopOrLaptop ? console.log('large screen') : setMessagesView({ displayChatView: true, displayConversationList: false, displayUserChatProfile: false });
  };

  const goToConversationList = () => {
    setMessagesView({ displayChatView: false, displayConversationList: true, displayUserChatProfile: false });
  };

  const goToChatView = () => {
    setMessagesView({ displayChatView: true, displayConversationList: false,  displayUserChatProfile: false });
  };

  const goToUserChatProfile = () => {
    if(isDesktopOrLaptop) return;
    setMessagesView({ displayChatView: false, displayConversationList: false,  displayUserChatProfile: true });
  };

  const handleMessage = (message) => {
    const input =   {
      content: message,
      type: 'text',
      authorId: username,
      messageConversationId: convoLinkConversationId,
      read: false
  }
    createMessage(input, () => updateConversation({id: convoLinkConversationId}, () => console.log('conversation updated')));
  }

  useEffect(() => {
    if (isDesktopOrLaptop) {
      setMessagesView({
        displayConversationList: true,
        displayChatView: true,
        displayUserChatProfile: true,
      });
    } 
  }, [isDesktopOrLaptop]);

  useEffect(() => {
     if(!isDesktopOrLaptop) {
      setMessagesView({
        displayConversationList: true,
        displayChatView: false,
        displayUserChatProfile: false,
      });
    }
  }, [isDesktopOrLaptop]);

  return (
    <div className={styles.chatPage}>
      {displayConversationList && <ConversationList unreadMessages={unreadMessages} username={username} convoLinkUserId={convoLinkUserId} conversationId={convoLinkConversationId} gettingUserAndConversations={gettingUserAndConversations} userConversations={userConversations} onChangeConversation={onChangeConversation} />}
      {(isDesktopOrLaptop || displayChatView) && (
        <ChatView
          convoLinkUserId={convoLinkUserId}
          messages={messages}
          gettingMessages={gettingUserAndConversations}
          goToConversationList={goToConversationList}
          goToUserChatProfile={goToUserChatProfile}
          username={username}
          onSubmit={handleMessage}
          creatingMessage={creatingMessage}
          setMessagesFeedInView={setMessagesFeedInView}
        />
      )}
      {(isDesktopOrLaptop || displayUserChatProfile) && <ChatUserProfile convoLinkUserId={convoLinkUserId} goToChatView={goToChatView} />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  gettingUserAndConversations: state.chat.gettingUserAndConversations,
  userConversations: state.chat.userConversations,
  messagesView: state.app.messagesView,
  creatingMessage: state.chat.creatingMessage,
  username: state.auth.loggedInUserInfo['custom:userName'],
  currentConversationDetails: state.chat.currentConversationDetails,
  unreadMessages: state.chat.unreadMessages,
  updatedMessage: state.chat.updatedMessage,
});

const mapDispatchToProps = {setMessagesFeedInView, getUserAndConversations,updateMessage, updateConversation, setMessagesView, createMessage, setCurrentConversationDetails };

export default connect(mapStateToProps, mapDispatchToProps)(ChatPage);
