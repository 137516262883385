import React, { Fragment } from 'react';

import Accordion from 'src/ui/Accordion';

import './Help.css';

import { MailIcon } from 'src/assets/svgs';

import { linkToTroperialSupportEmail } from '../../data/pageRoutes';

import { FAQ_LIST } from '../../data/text';

const Help = () => {
  return (
    <section className="help__container">
      <header className="help__header">
        <div className="page-introduction-title">
          <div>
            <h1 className="help-title">Help &amp; advice from the Troperial Team</h1>
            <form className="help__search">
              <input type="text" placeholder="search" className="help__search--form" />
            </form>
          </div>
        </div>
      </header>
      <section className="faq__section">
        <div className="accordion-group">
          {FAQ_LIST.map((faq) => (
            <Fragment key={faq.id}>
              <Accordion title={faq.title} content={faq.content} />
            </Fragment>
          ))}
        </div>

        <div className="send__email--section">
          <p>Don't see your question here?</p>
          <div className="send_email-button">
            <MailIcon/>
            <br />
            <a className="send-us-mail" href={linkToTroperialSupportEmail}>
              send us a mail
            </a>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Help;
