import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import { Auth, API } from 'aws-amplify';

import logger from 'redux-logger';
import thunk from 'redux-thunk';

import createReducers from './reducer';

import apiRequestMiddleware from './middlewares/apiRequestMiddleware';

import {
  accountsApiClient,
  personsApiClient,
  transactionsApiClient,
} from 'src/helpers/libs/api-client';

const initStore = () => {
  const rootReducer = combineReducers(createReducers());
  const middlewares = [
    thunk,
    apiRequestMiddleware({
      accountsApiClient,
      personsApiClient,
      transactionsApiClient,
      auth: Auth,
      api: API
    }),
  ];

  let composedMiddlewares;
  if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger);
    composedMiddlewares = composeWithDevTools(applyMiddleware(...middlewares));
  } else {
    composedMiddlewares = applyMiddleware(...middlewares);
  }

  const store = createStore(rootReducer, composedMiddlewares);
  return { store };
};

export default initStore;
