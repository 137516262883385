import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";


import styles from "./button.module.scss";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

const Button = ({ block, className, loading, shadow, variant, onClick, ...props }) => {
  let Elem = "button";
  if (props.to) {
    Elem = Link;
  }
  return (
    <Elem
      onClick={onClick}
      className={cx(styles.button, className, {
        [styles.button_block]: block,
        [styles.button__disabled]: props.disabled && !loading,
        [styles.button__loading]: loading,
        [styles.button_shadow]: shadow,
        [styles[`button_${variant}`]]: variant,
      })}
      {...props}
    >
      {loading ? <Loader type="ThreeDots" color="#FFF" height={'auto'} width={30} /> : [props.children]}
    </Elem>
  );
};

Button.propTypes = {
  block: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  shadow: PropTypes.bool,
  variant: PropTypes.string.isRequired,
};

export default Button;
