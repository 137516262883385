import { REQUIRED_PASSWORD_ERROR, VALID_PASSWORD_ERROR, REQUIRED_PHONE_ERROR, VALID_PHONE_ERROR, VALID_EMAIL_ERROR, VALID_USERNAME_ERROR, REQUIRED_EMAIL_ERROR, REQUIRED_FIRST_NAME_ERROR, REQUIRED_LAST_NAME_ERROR, REQUIRED_USERNAME_ERROR, VALID_FIRST_NAME_ERROR, VALID_LAST_NAME_ERROR } from "./error-messages";
import { NAME_REGEX, EMAIL_REGEX, PHONE_REGEX, PASSWORD_REGEX } from "./regex";

export const validation = {
  username: {
    required: { value: true, message: REQUIRED_USERNAME_ERROR },
    minLength: { value: 6, message: VALID_USERNAME_ERROR },
    maxLength: { value: 8, message: VALID_USERNAME_ERROR },
  },
  firstname: {
    required: { value: true, message: REQUIRED_FIRST_NAME_ERROR },
    pattern: { value: NAME_REGEX, message: VALID_FIRST_NAME_ERROR },
  },
  lastname: {
    required: { value: true, message: REQUIRED_LAST_NAME_ERROR },
    pattern: { value: NAME_REGEX, message: VALID_LAST_NAME_ERROR },
  },
  email: {
    required: { value: true, message: REQUIRED_EMAIL_ERROR },
    pattern: { value: EMAIL_REGEX, message: VALID_EMAIL_ERROR },
  },
  phone: {
    required: { value: true, message: REQUIRED_PHONE_ERROR },
    pattern: { value: PHONE_REGEX, message: VALID_PHONE_ERROR },
  },
  password: {
    required: { value: true, message: REQUIRED_PASSWORD_ERROR },
    pattern: { value: PASSWORD_REGEX, message: VALID_PASSWORD_ERROR },
  },
};
