import auth from './modules/auth/reducer';
import chat from './modules/chat/reducer';
import app from './modules/app/reducer';
import persons from './modules/persons/reducer';

export default function createReducers() {
  return {
    app,
    auth,
    chat,
    persons,
  };
}
