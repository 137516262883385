// GET USER FROM DYNAMO DB
export const GET_USER_FROM_DYNAMODB_REQUEST = 'GET_USER_FROM_DYNAMODB_REQUEST';
export const GET_USER_FROM_DYNAMODB_SUCCESS = 'GET_USER_FROM_DYNAMODB_SUCCESS';
export const GET_USER_FROM_DYNAMODB_FAILURE = 'GET_USER_FROM_DYNAMODB_FAILURE';

// ADD USER TO DYNAMO DB
export const ADD_USER_TO_DYNAMODB_REQUEST = 'ADD_USER_TO_DYNAMODB_REQUEST';
export const ADD_USER_TO_DYNAMODB_SUCCESS = 'ADD_USER_TO_DYNAMODB_SUCCESS';
export const ADD_USER_TO_DYNAMODB_FAILURE = 'ADD_USER_TO_DYNAMODB_FAILURE';

// GET USER AND CONVERSATIONS
export const GET_USER_AND_CONVERSATIONS_REQUEST = "GET_USER_AND_CONVERSATIONS_REQUEST"
export const GET_USER_AND_CONVERSATIONS_SUCCESS = "GET_USER_AND_CONVERSATIONS_SUCCESS"
export const GET_USER_AND_CONVERSATIONS_FAILURE = "GET_USER_AND_CONVERSATIONS_FAILURE"

export const BG_GET_USER_AND_CONVERSATIONS_REQUEST = "BG_GET_USER_AND_CONVERSATIONS_REQUEST"
export const BG_GET_USER_AND_CONVERSATIONS_SUCCESS = "BG_GET_USER_AND_CONVERSATIONS_SUCCESS"
export const BG_GET_USER_AND_CONVERSATIONS_FAILURE = "BG_GET_USER_AND_CONVERSATIONS_FAILURE"

// CREATE CONVERSATION
export const CREATE_CONVERSATION_REQUEST = "CREATE_CONVERSATION_REQUEST"
export const CREATE_CONVERSATION_SUCCESS = "CREATE_CONVERSATION_SUCCESS"
export const CREATE_CONVERSATION_FAILURE = "CREATE_CONVERSATION_FAILURE"

// UPDATE CONVERSATION
export const UPDATE_CONVERSATION_REQUEST = "UPDATE_CONVERSATION_REQUEST"
export const UPDATE_CONVERSATION_SUCCESS = "UPDATE_CONVERSATION_SUCCESS"
export const UPDATE_CONVERSATION_FAILURE = "UPDATE_CONVERSATION_FAILURE"

// CREATE MESSAGE
export const CREATE_MESSAGE_REQUEST = "CREATE_MESSAGE_REQUEST"
export const CREATE_MESSAGE_SUCCESS = "CREATE_MESSAGE_SUCCESS"
export const CREATE_MESSAGE_FAILURE = "CREATE_MESSAGE_FAILURE"

// UPDATE MESSAGE
export const UPDATE_MESSAGE_REQUEST = "UPDATE_MESSAGE_REQUEST" 
export const UPDATE_MESSAGE_SUCCESS = "UPDATE_MESSAGE_SUCCESS" 
export const UPDATE_MESSAGE_FAILURE = "UPDATE_MESSAGE_FAILURE"

// SET CURRENT CONVERSATION DETAILS
export const SET_CURRENT_CONVERSATION_DETAILS = "SET_CURRENT_CONVERSATION_DETAILS";
// UPDATE CONVERSATIONS
export const UPDATE_CONVERSATIONS = "UPDATE_CONVERSATIONS";
// UPDATE MESSAGES
export const UPDATE_MESSAGES = "UPDATE_MESSAGES";
// GET UNREAD MESSAGES
export const GET_UNREAD_MESSAGES = "GET_UNREAD_MESSAGES";