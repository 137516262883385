import React from 'react';

import Avatar from 'src/ui/Avatar';
import Button from 'src/ui/Button';
import Divider from '../../../ui/Divider';

import { CircleArrowLeftIcon } from 'src/assets/svgs';

import styles from './chat-user-profile.module.scss';

const ChatUserProfile = ({ goToChatView, convoLinkUserId  }) => {
  return (
    <div className={styles.chatUserProfile}>
      <div className={styles.chatUserProfile_backContainer}>
        <CircleArrowLeftIcon
          onClick={goToChatView}
          className={styles.chatUserProfile_backContainer_icon}
        />
      </div>
      <div className={styles.chatUserProfile_profileInfo}>
        <Avatar size="M" username={null} fullName="Runo saduwa" />
        <h2 className={styles.chatUserProfile_profileInfo_username}>@{convoLinkUserId}</h2>
        <p className={styles.chatUserProfile_profileInfo_trades}>15 successful trades</p>
        <Divider className={styles.chatUserProfile_profileInfo_divider} />
        <Button className={styles.chatUserProfile_profileInfo_btn} variant="themedAlt">
          Mark as trusted trader
        </Button>
        <p className={styles.chatUserProfile_profileInfo_joined}>Joined 2 months ago</p>
      </div>
    </div>
  );
};

export default ChatUserProfile;
