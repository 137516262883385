import React from 'react';
import styles from './avatar.module.scss';
import cx from 'classnames';


const Avatar = ({ fullName, imageUri, username, className, size, onClick }) => {
  const firstNameLetter = fullName.split(' ')[0].charAt(0).toUpperCase();
  const lastNameLetter = fullName.split(' ')[1].charAt(0).toUpperCase();
  return (
    <div className={styles.avatar} onClick={onClick}>
      {imageUri ? (
        <img src={imageUri} className={cx(className, styles.avatar_image, {[styles[`avatar_${size}`]]: size})} />
      ) : (
        <div className={cx(className, styles.avatar_placeholder, {[styles[`avatar_${size}`]]: size})}>
          <p>{`${firstNameLetter}${lastNameLetter}`}</p>
        </div>
      )}

      <p className={styles.avatar_username}>{username}</p>
    </div>
  );
};

Avatar.defaultProps = {
  fullName: 'John Doe',
  username: '',
  imageUri: null,
  size: 'S', // S | M | L
  onClick: ()=> {}
};

export default Avatar;
