import React from 'react';
import { useForm } from 'react-hook-form';

import Label from 'src/ui/Label';
import Button from 'src/ui/Button';
import { FormField } from 'src/ui/Form';

import { AppLogo } from 'src/assets/svgs';

import { validation } from 'src/helpers/validation';

import styles from './login.module.scss';

const Login = ({ onSubmit, loggingIn }) => {
  const { handleSubmit, register, errors } = useForm({ reValidateMode: 'onChange' });

  return (
    <div className={styles.login}>
      <AppLogo />
      <Label
        title={() => (
          <h1 className={styles.login_heading}>
            Sign In to <span>Troperial</span>
          </h1>
        )}
        subTitle="Enter your email address & password to sign In to Troperial"
      />
      <form className={styles.login_form} onSubmit={handleSubmit(onSubmit)}>
        <FormField
          register={register(validation.email)}
          onChange={(e) => e.target.value.trim()}
          label="Email"
          error={errors.email}
          errorMessage={errors?.email?.message}
          placeholder="bosun@gmail.com"
          name="email"
        />
        <FormField
          register={register({ required: validation.password.required })}
          onChange={(e) => e.target.value.trim()}
          label="Password"
          error={errors.password}
          errorMessage={errors?.password?.message}
          placeholder="Password"
          name="password"
          type="password"
        />
        <Button variant="themed" block disabled={loggingIn} loading={loggingIn}>
          Sign In
        </Button>
      </form>
      <div className={styles.login_linkSection}>
        <p className={styles.login_linkSection_p}>
          Forgot Password?{' '}
          <Button
            variant="themedLink"
            to="/forgot-password"
            className={styles.login_linkSection_p_link}
          >
            Click here
          </Button>
        </p>
        <p className={styles.login_linkSection_p}>
          Don’t have an account?{' '}
          <Button variant="themedLink" to="/signup" className={styles.login_linkSection_p_link}>
            Sign up
          </Button>
        </p>
      </div>
    </div>
  );
};

export default Login;
