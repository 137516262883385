import { notifyError } from './libs/toast';

export const handleError = (err) => {
  if (err.message) {
    notifyError(err.message);
    throw err;
  } else {
    notifyError(err);
    throw err;
  }
};
