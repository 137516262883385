import React from 'react';
import { BoltGoldenIcon, SheildLockIcon, PhoneActionIcon, CreditCardIcon } from 'src/assets/svgs';

import styles from './section-four.module.scss';

const SectionFour = () => {
  return (
    <section className={styles.sectionFour}>
      <header className={styles.sectionFour_header}>
        <h1 className={styles.sectionFour_header_title}>Why choose Troperial?</h1>
      </header>

      <div className={styles.sectionFour_grid}>
        <div className={styles.sectionFour_grid_card}>
          <BoltGoldenIcon className={styles.sectionFour_grid_card_vectorIcon} />
          <h1 className={styles.sectionFour_grid_card_title}>It’s fast</h1>
          <p className={styles.sectionFour_grid_card_desc}>
            Complete a transaction in three steps - Post, Negotiate, Transact.
          </p>
        </div>
        <div className={styles.sectionFour_grid_card}>
          <PhoneActionIcon className={styles.sectionFour_grid_card_vectorIcon} />
          <h1 className={styles.sectionFour_grid_card_title}>It’s simple</h1>
          <p className={styles.sectionFour_grid_card_desc}>Very easy to use.</p>
        </div>
        <div className={styles.sectionFour_grid_card}>
          <CreditCardIcon className={styles.sectionFour_grid_card_vectorIcon} />
          <h1 className={styles.sectionFour_grid_card_title}>Absolutely no fees</h1>
          <p className={styles.sectionFour_grid_card_desc}>Zero charges.</p>
        </div>
        <div className={styles.sectionFour_grid_card}>
          <SheildLockIcon className={styles.sectionFour_grid_card_vectorIcon} />
          <h1 className={styles.sectionFour_grid_card_title}>It’s safe</h1>
          <p className={styles.sectionFour_grid_card_desc}>
            We request all users to verify their identity, data is securely transferred to and from
            the website via SSL and we encrypt all personal information collected using the highest
            encryption standard.
          </p>
        </div>
      </div>
    </section>
  );
};

export default SectionFour;
