import React from 'react';

import PublicNav from 'src/components/Nav/PublicNav';
import Button from 'src/ui/Button';

import { InstagramIcon, TwitterIcon, LinkedInIcon, FacebookIcon } from 'src/assets/svgs';
import logo from '../../assets/svgs/icons/Logo.svg';

import styles from './website-layout.module.scss';
import { linkToTroperialSupportEmail } from 'src/data/pageRoutes';

const WebsiteLayout = ({ children }) => {
  return (
    <div>
      <PublicNav />
      {children}
      <footer className={styles.footer}>
        <section className={styles.footer_header}>
          <div className={styles.footer_header_intro}>
            <p className={styles.footer_header_intro_title}>Try Troperial Now</p>
            <h1 className={styles.footer_header_intro_subtitle}>Get the best exchange rates from trusted traders</h1>
            <Button variant="themed" className={styles.footer_header_intro_btn}>Create an account</Button>
          </div>
        </section>
        <section className={styles.footer_linksSection}>
          <div className={styles.footer_linksSection_item}>
            <img src={logo} alt="troperial" className={styles.footer_linksSection_item_logo}/>
            <p>
              © Copyright {new Date().getFullYear()}
              <br />
              Troperial Technologies LLC. <br />
              All rights reserved.
            </p>
          </div>
          <div className={styles.footer_linksSection_item}>
            <h2 className={styles.footer_linksSection_item_title}>Company</h2>
            <Button className={styles.footer_linksSection_item_link} to="/" variant="footerLink">
              Home
            </Button>
            <Button className={styles.footer_linksSection_item_link}  to="/" variant="footerLink">
              How it works
            </Button>
            <Button className={styles.footer_linksSection_item_link}  to="/" variant="footerLink">
              About
            </Button>
          </div>
          <div className={styles.footer_linksSection_item}>
            <h2 className={styles.footer_linksSection_item_title}>Legal</h2>
            <Button className={styles.footer_linksSection_item_link}  to="/" variant="footerLink">
              Legal Notice
            </Button>
            <Button className={styles.footer_linksSection_item_link}  to="/" variant="footerLink">
              Privacy Policy
            </Button>
            <Button className={styles.footer_linksSection_item_link}  to="/" variant="footerLink">
              Terms of Use
            </Button>
          </div>
          <div className={styles.footer_linksSection_item}>
            <h2 className={styles.footer_linksSection_item_title}>Stay in touch</h2>
            <a className={styles.footer_linksSection_item_support} href={linkToTroperialSupportEmail}>
              support@troperial.com
            </a>
            <Button className={styles.footer_linksSection_item_iconLink} to="/" variant="themedIcon">
              <TwitterIcon />
            </Button>
            <Button className={styles.footer_linksSection_item_iconLink} to="/" variant="themedIcon">
              <InstagramIcon />
            </Button>
            <Button className={styles.footer_linksSection_item_iconLink} to="/" variant="themedIcon">
              <LinkedInIcon />
            </Button>
            <Button className={styles.footer_linksSection_item_iconLink} to="/" variant="themedIcon">
              <FacebookIcon />
            </Button>
          </div>
        </section>
      </footer>
    </div>
  );
};

export default WebsiteLayout;
