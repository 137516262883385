import axios from "axios";
import config from "../../../config";

const authToken = localStorage.getItem('authToken');

const apiClient = (baseURL) => (
  axios.create({
    baseURL: baseURL,
    headers: {
      "Content-Type": "application/json",
      "Authorization": authToken,
    },
  })
)

export const accountsApiClient = apiClient(config.accountsApiUrl);
export const personsApiClient = apiClient(config.personsApiUrl);
export const transactionsApiClient = apiClient(config.transactionsApiUrl);
