import { SET_MESSAGES_VIEW, SET_MESSAGES_FEED_INVIEW } from './types';

export const setMessagesView = (value) => (dispatch) =>
  dispatch({
    type: SET_MESSAGES_VIEW,
    payload: value,
  });
export const setMessagesFeedInView = (value) => (dispatch) =>
  dispatch({
    type: SET_MESSAGES_FEED_INVIEW,
    payload: value,
  });
