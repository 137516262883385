import React from 'react';
import moment from 'moment';
import cx from 'classnames';
import styles from './text-chat-bubble.module.scss';


const TextChatBubble = ({ createdAt, username, authorId, content, ...props}) => {
  return (
    <div key={props.key} className={styles.bubbleContainer}>
      <div
        className={cx(
          styles.bubbleContainer_messageBubble,
          authorId === username ? styles.bubbleContainer_messageBubble_fromMe : null,
        )}
      >
        <p
          className={cx(
            styles.bubbleContainer_messageBubble_text,
            authorId === username ? styles.bubbleContainer_messageBubble_text_fromMe : null,
          )}
        >
          {content}
        </p>
      </div>
      <span
        className={cx(
          styles.bubbleContainer_messageBubble_time,
          authorId === username ? styles.bubbleContainer_messageBubble_time_fromMe : null,
        )}
      >
        {moment(createdAt).format('lll')}
      </span>
    </div>
  );
};

TextChatBubble.defaultProps = {
  createdAt: new Date('2021-01-22'),
  content: 'hello world',
  authorId: 'test',
  username: 'test',
};

export default TextChatBubble;
