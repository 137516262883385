import React from 'react';

import OtpInput from 'src/ui/OtpInput';
import Label from 'src/ui/Label';
import Button from 'src/ui/Button';

import styles from './enter-verification-code.module.scss';

const EnterVerificationCode = ({ setVerificationStep, onSubmit }) => {
  const handleVerificationCode = () => {
    onSubmit()
  }
  return (
    <div className={styles.enterVerificationCode}>
      <Label
        title={() => (
          <h1 className={styles.enterVerificationCode_heading}>Enter Verification code</h1>
        )}
        subTitle={() => (
          <p className={styles.enterVerificationCode_subtitle}>
            Kindly enter the 4-digit verification code that was sent to{' '}
            <span>+ 234 814 8476 894</span>
          </p>
        )}
      />
      <div className={styles.enterVerificationCode_form}>
        <OtpInput />
        <div className={styles.enterVerificationCode_form_linkSection}>
          <p className={styles.enterVerificationCode_form_linkSection_p}>
            Didn’t receive any code?{' '}
            <Button
              variant="themedClear"
              className={styles.enterVerificationCode_form_linkSection_p_link}
            >
              Resend code
            </Button>
          </p>
        </div>
        <Button variant="themed" block onClick={handleVerificationCode}>
          Verify and Continue
        </Button>
      </div>
      <Button
        className={styles.enterVerificationCode_prevBtn}
        block
        variant="none"
        onClick={() => setVerificationStep(2)}
      >
        Previous step
      </Button>
    </div>
  );
};

export default EnterVerificationCode;
