import React, { useState } from 'react';

// import caret from '../../assets/svgs/green-caret.svg';
// import plus from '../../assets/svgs/plus.svg';
// import minus from '../../assets/svgs/minus.svg';

import './accordion.css';
import { GreenCaretIcon, PlusIcon, MinusIcon } from 'src/assets/svgs';

const Accordion = ({ title, content }) => {
  const [toggleAccordion, setToggleAccordion] = useState(false);

  return (
    <div className="accordion" onClick={() => setToggleAccordion(!toggleAccordion)}>
      <div className="accordion__header">
        <div className="accordion_title">
          <GreenCaretIcon />
          <span className="text">{title}</span>
        </div>
        <button className="accordion__toggleBtn">
          {!toggleAccordion ? <PlusIcon /> : <MinusIcon />}
        </button>
      </div>
      {toggleAccordion && (
        <div className="accordion__body">
          <p>{content}</p>
        </div>
      )}
    </div>
  );
};

export default Accordion;
