import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Amplify from 'aws-amplify';

import * as serviceWorker from './serviceWorker';
import awsExports from './aws-exports';
import { App } from './app';
import { initStore } from './redux';

Amplify.configure(awsExports);
const { store } = initStore();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.register();
