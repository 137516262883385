import React, { useState } from 'react';

import PhoneInput from 'react-phone-number-input';
import cx from 'classnames';

import { CaretDown } from 'src/assets/svgs';

import 'react-phone-number-input/style.css';
import styles from './phone-input-field.module.scss';

const PhoneInputField = ({ onChange, value, ...props }) => {
  const [focused, setFocused] = useState(false);
  return (
    <PhoneInput
      {...props}
      countrySelectProps={{
        arrowComponent: () => <CaretDown className={styles.phoneInput_caret} />,
      }}
      value={value}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      onChange={onChange}
      className={cx(styles.phoneInput, focused === true ? styles.phoneInput_focused : null)}
    />
  );
};

PhoneInputField.defaultProps = {
  international: true,
  addInternationalOption: false,
  withCountryCallingCode: true,
  value: '',
  onChange: () => {},
  defaultCountry: 'US',
  limitMaxLength: true,
  countryOptionsOrder: ['US', 'CA', 'AU', 'NG', '|', '...'],
};

export default PhoneInputField;
