import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styles from './reset-password.module.scss';

import Label from 'src/ui/Label';
import Button from 'src/ui/Button';
import { FormField } from 'src/ui/Form';
import Meter from 'src/ui/Meter';

import { AppLogo } from 'src/assets/svgs';

import { validation } from 'src/helpers/validation';

const ResetPasswordForm = ({ onSubmit }) => {
  const location = useHistory();
  const { register, handleSubmit, errors, watch } = useForm({ reValidateMode: 'onChange' });
  const [passwordTrack, setPasswordTrack] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const password = useRef({});

  password.current = watch('password', '');

  return (
    <div className={styles.resetPassword}>
      <AppLogo onClick={() => location.push('/')} />
      <Label
        title={() => (
          <h1 className={styles.resetPassword_heading}>
            Set a new <span>Password</span>
          </h1>
        )}
        subTitle="Make sure to set a password that's
        unique to you, difficult and not easy to guess
        "
      />
      <form className={styles.resetPassword_form} onSubmit={handleSubmit(onSubmit)}>
        <FormField
          register={register({
            required: { value: true, message: 'Verification code is required' },
          })}
          onChange={(e) => e.target.value.trim()}
          label="Verification Code"
          error={errors.code}
          errorMessage={errors?.code?.message}
          placeholder="Verification code"
          name="code"
        />
        <FormField
          register={register(validation.password)}
          onChange={(e) => {
            e.target.value.trim();
            setPasswordTrack(e.target.value);
          }}
          hint="Password must be between 6 - 20 characters and must include atleast 1 Uppercase letter, 1 Lowercase letter, 1 numeric value and one special character."
          label="Password"
          error={errors.password}
          errorMessage={errors?.password?.message}
          placeholder="Create a Password"
          name="password"
          type="password"
        />
        <FormField
          onChange={(e) => e.target.value.trim()}
          label="Confirm Password"
          error={confirmPasswordError}
          errorMessage={confirmPasswordError}
          placeholder="Confirm Password"
          name="confirmPassword"
          type="password"
          register={register({
            required: true,
            validate: (value) => {
              if (value === password.current) {
                setConfirmPasswordError('');
              } else {
                setConfirmPasswordError('Password does not match');
              }
            },
          })}
        />
        <Meter value={passwordTrack} />
        <Button variant="themed" block>
          Reset Password
        </Button>
      </form>
      <div className={styles.resetPassword_linkSection}>
        <p className={styles.resetPassword_linkSection_p}>
          <Button
            variant="themedLink"
            to="/login"
            className={styles.resetPassword_linkSection_p_link}
          >
            Back to Sign In
          </Button>
        </p>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
