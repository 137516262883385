import React from 'react';
import Images from 'src/assets/images';

import { ChatBubbleIcon } from 'src/assets/svgs';
import Illustrations from 'src/assets/svgs/illustrations';

import styles from './section-five.module.scss';

const SectionFive = () => {
  return (
    <section className={styles.sectionFive}>
      <div className={styles.sectionFive_content}>
          <ChatBubbleIcon className={styles.sectionFive_content_iconMobile} />
          <h1 className={styles.sectionFive_content_title}>What people are saying</h1>
          <div className={styles.sectionFive_content_testimony}>
            <ChatBubbleIcon className={styles.sectionFive_content_testimony_iconDesktop} />
            <div className={styles.sectionFive_content_testimony_statement}>
              <p>
              "I got a 10% increase when I started exchanging my US dollars paycheck for Nigerian Naira on Troperial, where I got a better exchange rate and avoided excess bank charges."
              </p>
              <div className={styles.sectionFive_content_testimony_statement_user}>
                <img src={Images.person1} alt="man" />
                <span>Yemi, 🇳🇬</span>
              </div>
            </div>
          </div>
      </div>

      <div className={styles.sectionFive_illustrationSection}>
        <img src={Illustrations.platform} alt="listing platform" />
      </div>
    </section>
  );
};

export default SectionFive;
