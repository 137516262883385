import React from 'react';
import Div100vh from 'react-div-100vh';
import { useMediaQuery } from 'react-responsive';

import { MEDIA_QUERY } from 'src/data';

const ChatFeedWrapper = ({ children, className }) => {
  const isDesktopOrLaptop = useMediaQuery(MEDIA_QUERY.isDesktopOrLaptop);
  if (isDesktopOrLaptop) {
    return <div className={className}>{children}</div>;
  } else {
    return <Div100vh className={className}>{children}</Div100vh>;
  }
};

export default ChatFeedWrapper;
