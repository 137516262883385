import React, { useEffect } from 'react';
import { Notifications } from 'react-push-notification';
import addNotification from 'react-push-notification';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import ErrorBoundary from './ErrorBoundary';

import AppRouter from './AppRouter';

import { API, graphqlOperation } from 'aws-amplify';
import * as subscriptions from '../graphql/subscriptions';

import { getAuthenticatedUser, getLoggedInUserInfo } from 'src/redux/modules/auth';
import {
  updateConversations,
  updateMessages,
  getUserAndConversations,
  getUnreadMessages,
  updateMessage,
} from 'src/redux/modules/chat';

import { MEDIA_QUERY } from 'src/data';

import '../styles/generic.scss';

const App = ({
  updateMessage,
  messagesFeedInView,
  getUnreadMessages,
  userConversations,
  getUserAndConversations,
  updateMessages,
  updateConversations,
  getAuthenticatedUser,
  getLoggedInUserInfo,
  username,
  isAuthenticating,
  isLoggedIn,
  currentConversationDetails,
}) => {
  const { convoLinkConversationId } = currentConversationDetails;
  const isDesktopOrLaptop = useMediaQuery(MEDIA_QUERY.isDesktopOrLaptop);
  useEffect(() => {
    const userSubscription = API.graphql(
      graphqlOperation(subscriptions.OnUpdateMessage, {
        conversationId: convoLinkConversationId,
      }),
    ).subscribe({
      next: (result) => updateMessages(result.value?.data?.onUpdateMessage),
    });

    return () => userSubscription.unsubscribe();
  });

  useEffect(() => {
    const messageSubscription = API.graphql(
      graphqlOperation(subscriptions.OnCreateMessageListener),
    ).subscribe({
      next: (result) => {
        if (
          messagesFeedInView &&
          result.value.data.onCreateMessageListener.messageConversationId ===
            convoLinkConversationId &&
          result.value.data.onCreateMessageListener.authorId !== username
        ) {
          updateMessage({ id: result.value.data.onCreateMessageListener.id, read: true }, () =>
            console.log('message updated'),
          );
        }
        // might consider removing
        getUserAndConversations('background', username, () => console.log(`new message from`));
        if (
          result.value.data.onCreateMessageListener.messageConversationId ===
          convoLinkConversationId
        ) {
          updateMessages(result.value.data.onCreateMessageListener);
        }
        const newMessage = userConversations
          .filter((convo) => convo.id !== convoLinkConversationId)
          .find(
            (convo) => convo.id === result.value.data.onCreateMessageListener.messageConversationId,
          );
        console.log(newMessage);

        if (Boolean(newMessage)) {
          addNotification({
            title: `🔔 New Message from ${result.value.data.onCreateMessageListener.authorId}`,
            message: `${result.value.data.onCreateMessageListener.content}`,
            native: true,
          });
          getUnreadMessages(result.value.data.onCreateMessageListener);
        }
      },
    });

    return () => messageSubscription.unsubscribe();
  });

  useEffect(() => {
    getAuthenticatedUser(() => getLoggedInUserInfo(() => console.log('info retrieved!')));
  }, []);
  return (
    <ErrorBoundary>
      <Notifications position={isDesktopOrLaptop ? "bottom-right" : 'bottom-middle'} />
      {!isAuthenticating ? <AppRouter isLoggedIn={isLoggedIn} /> : <p>Loading...</p>}
    </ErrorBoundary>
  );
};

const mapStateToProps = ({
  auth: { isLoggedIn, isAuthenticating, loggedInUserInfo },
  chat,
  app,
}) => ({
  isLoggedIn,
  isAuthenticating,
  username: loggedInUserInfo['custom:userName'],
  currentConversationDetails: chat.currentConversationDetails,
  userConversations: chat.userConversations,
  messagesFeedInView: app.messagesFeedInView,
});

const mapDispatchToProps = {
  getAuthenticatedUser,
  getLoggedInUserInfo,
  updateConversations,
  getUserAndConversations,
  updateMessages,
  getUnreadMessages,
  updateMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
