import {
  GET_USER_FROM_DYNAMODB_REQUEST,
  GET_USER_FROM_DYNAMODB_SUCCESS,
  GET_USER_FROM_DYNAMODB_FAILURE,
  ADD_USER_TO_DYNAMODB_REQUEST,
  ADD_USER_TO_DYNAMODB_SUCCESS,
  ADD_USER_TO_DYNAMODB_FAILURE,
  GET_USER_AND_CONVERSATIONS_REQUEST,
  GET_USER_AND_CONVERSATIONS_SUCCESS,
  GET_USER_AND_CONVERSATIONS_FAILURE,
  BG_GET_USER_AND_CONVERSATIONS_REQUEST,
  BG_GET_USER_AND_CONVERSATIONS_SUCCESS,
  BG_GET_USER_AND_CONVERSATIONS_FAILURE,
  CREATE_CONVERSATION_REQUEST,
  CREATE_CONVERSATION_SUCCESS,
  CREATE_CONVERSATION_FAILURE,
  UPDATE_CONVERSATION_REQUEST,
  UPDATE_CONVERSATION_SUCCESS,
  UPDATE_CONVERSATION_FAILURE,
  CREATE_MESSAGE_REQUEST,
  CREATE_MESSAGE_SUCCESS,
  CREATE_MESSAGE_FAILURE,
  UPDATE_MESSAGE_REQUEST,
  UPDATE_MESSAGE_SUCCESS,
  UPDATE_MESSAGE_FAILURE,
  SET_CURRENT_CONVERSATION_DETAILS,
  UPDATE_CONVERSATIONS,
  UPDATE_MESSAGES,
  GET_UNREAD_MESSAGES,
} from './types';

import asyncWrapper from 'src/helpers/asyncWrapper';
import { handleError } from 'src/helpers/errorHandlers';

import * as queries from '../../../graphql/queries';
import * as mutations from '../../../graphql/mutations';

import { graphqlOperation } from 'aws-amplify';

export const getUserFromDynamoDb = (id, successCb) => ({
  types: [
    GET_USER_FROM_DYNAMODB_REQUEST,
    GET_USER_FROM_DYNAMODB_SUCCESS,
    GET_USER_FROM_DYNAMODB_FAILURE,
  ],
  promise: async ({ api }) => {
    const [result, err] = await asyncWrapper(
      api.graphql(graphqlOperation(queries.GetUser, { id })),
    );

    if (err) {
      console.log(err);
      handleError('Something went wrong');
    }

    if (result) {
      successCb(result.data.getUser);
      console.log(result);
      return result.data.getUser;
    }
  },
});

export const addUserToDynamoDb = (user, successCb) => ({
  types: [ADD_USER_TO_DYNAMODB_REQUEST, ADD_USER_TO_DYNAMODB_SUCCESS, ADD_USER_TO_DYNAMODB_FAILURE],
  promise: async ({ api }) => {
    const [result, err] = await asyncWrapper(
      api.graphql(graphqlOperation(mutations.CreateUser, { user })),
    );

    if (err) {
      console.log(err);
      handleError('Something went wrong');
    }

    if (result) {
      successCb();
      console.log(result);
      return result.data.createUser;
    }
  },
});

export const createMessage = (input, successCb) => ({
  types: [CREATE_MESSAGE_REQUEST, CREATE_MESSAGE_SUCCESS, CREATE_MESSAGE_FAILURE],
  promise: async ({ api }) => {
    const [result, err] = await asyncWrapper(
      api.graphql(graphqlOperation(mutations.CreateMessage, { input })),
    );

    if (err) {
      console.log(err);
      handleError('Something went wrong');
    }

    if (result) {
      successCb();
      console.log(result);
      return result.data.createMessage;
    }
  },
});

export const updateMessage = (input, successCb) => ({
  types: [UPDATE_MESSAGE_REQUEST, UPDATE_MESSAGE_SUCCESS, UPDATE_MESSAGE_FAILURE],
  promise: async ({ api }) => {
    const [result, err] = await asyncWrapper(
      api.graphql(graphqlOperation(mutations.UpdateMessage, { input })),
    );

    if (err) {
      console.log(err);
      handleError('Something went wrong');
    }

    if (result) {
      successCb();
      console.log(result);
      return result.data.updateMessage;
    }
  },
});
export const updateConversation = (input, successCb) => ({
  types: [UPDATE_CONVERSATION_REQUEST,
    UPDATE_CONVERSATION_SUCCESS,
    UPDATE_CONVERSATION_FAILURE],
  promise: async ({ api }) => {
    const [result, err] = await asyncWrapper(
      api.graphql(graphqlOperation(mutations.UpdateConvo, { input })),
    );

    if (err) {
      console.log(err);
      handleError('Something went wrong');
    }

    if (result) {
      successCb();
      console.log(result);
      return result.data.updateConversation;
    }
  },
});

export const createConversation = (user1, user2, successCb) => ({
  types: [CREATE_CONVERSATION_REQUEST, CREATE_CONVERSATION_SUCCESS, CREATE_CONVERSATION_FAILURE],
  promise: async ({ api }) => {
    let firstUserConvoLinkResponse;
    let firstUserConvoLinkErr;
    let secondUserConvoLinkResponse;
    let secondUserConvoLinkErr;

    const members = [user1, user2];
    console.log(members);
    const conversationName = members.join(' and ');
    console.log(conversationName);
    const createConvo = await asyncWrapper(
      api.graphql(
        graphqlOperation(mutations.CreateConvo, {
          input: {
            name: conversationName,
            members,
          },
        }),
      ),
    );
    const createConvoResponse = createConvo[0];
    const createConvoErr = createConvo[1];

    if (createConvoErr) {
      console.log(createConvoErr);
      throw console.log('Could not create conversation');
    }

    if (createConvoResponse) {
      console.log(createConvoResponse);
      const firstUserConvoLink = await asyncWrapper(
        api.graphql(
          graphqlOperation(mutations.CreateConvoLink, {
            input: {
              convoLinkUserId: user1,
              convoLinkConversationId: createConvoResponse.data.createConvo.id,
            },
          }),
        ),
      );
      firstUserConvoLinkResponse = firstUserConvoLink[0];
      firstUserConvoLinkErr = firstUserConvoLink[1];
    }

    if (firstUserConvoLinkErr) {
      console.error(firstUserConvoLinkErr);
      throw console.error('Opps could not create convo link');
    }

    if (firstUserConvoLinkResponse) {
      console.log(firstUserConvoLinkResponse);
      const secondUserConvoLink = await asyncWrapper(
        api.graphql(
          graphqlOperation(mutations.CreateConvoLink, {
            input: {
              convoLinkUserId: user2,
              convoLinkConversationId: createConvoResponse.data.createConvo.id,
            },
          }),
        ),
      );
      secondUserConvoLinkResponse = secondUserConvoLink[0];
      secondUserConvoLinkErr = secondUserConvoLink[1];
    }

    if (secondUserConvoLinkErr) {
      console.error(secondUserConvoLinkErr);
      throw console.error('Opps could not create second convo link');
    }

    if (secondUserConvoLinkResponse) {
      // conversationId and messages
      successCb(
        createConvoResponse.data.createConvo.id,
        createConvoResponse.data.createConvo.messages.items,
        createConvoResponse.data.createConvo,
      );
      return createConvoResponse.data.createConvo.id;
    }
  },
});

export const getUserAndConversations = (fetchType = 'default', username, successCb) => ({
  types:
    fetchType === 'default'
      ? [
          GET_USER_AND_CONVERSATIONS_REQUEST,
          GET_USER_AND_CONVERSATIONS_SUCCESS,
          GET_USER_AND_CONVERSATIONS_FAILURE,
        ]
      : [
          BG_GET_USER_AND_CONVERSATIONS_REQUEST,
          BG_GET_USER_AND_CONVERSATIONS_SUCCESS,
          BG_GET_USER_AND_CONVERSATIONS_FAILURE,
        ],
  promise: async ({ api }) => {
    const [result, err] = await asyncWrapper(
      api.graphql(graphqlOperation(queries.GetUserAndConversations, { id: username })),
    );

    if (err) {
      console.log(err);
      handleError('Something went wrong');
    }

    if (result) {
      const data = {
        conversations: result?.data?.getUser?.conversations?.items,
        loggedInUser: username,
      };
      successCb(result?.data?.getUser?.conversations?.items);
      return data;
    }
  },
});

export const getUnreadMessages = (unreadMessage) => (dispatch) => {
  dispatch({
    type: GET_UNREAD_MESSAGES,
    payload: unreadMessage,
  });
};

export const setCurrentConversationDetails = (value) => (dispatch) =>
  dispatch({
    type: SET_CURRENT_CONVERSATION_DETAILS,
    payload: value,
  });

export const updateConversations = (value) => (dispatch) =>
  dispatch({
    type: UPDATE_CONVERSATIONS,
    payload: value,
  });
export const updateMessages = (value) => (dispatch) =>
  dispatch({
    type: UPDATE_MESSAGES,
    payload: value,
  });
