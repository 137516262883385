import React from 'react';

import { useForm } from 'react-hook-form';

import Label from 'src/ui/Label';
import Button from 'src/ui/Button';
import { FormField } from 'src/ui/Form';

import { validation } from 'src/helpers/validation';

import styles from './account-setup.module.scss';

const AccountSetup = ({onSubmit}) => {
  const { handleSubmit, register, errors } = useForm({ reValidateMode: 'onChange' });
  return (
    <div className={styles.accountSetup}>
      <Label
        title={() => (
          <h1 className={styles.accountSetup_heading}>
            Set up your account
          </h1>
        )}
        subTitle="Enter your first name, last name & username to set up your Troperial account."
      />
      <form className={styles.accountSetup_form} onSubmit={handleSubmit(onSubmit)}>
        <FormField
          register={register(validation.firstname)}
          onChange={(e) => e.target.value.trim()}
          label="First name"
          error={errors.firstname}
          errorMessage={errors?.firstname?.message}
          placeholder="Enter first name"
          name="firstname"
        />
        <FormField
          register={register(validation.lastname)}
          onChange={(e) => e.target.value.trim()}
          label="Last name"
          error={errors.lastname}
          errorMessage={errors?.lastname?.message}
          placeholder="Enter last name"
          name="lastname"
        />
        <FormField
          register={register(validation.username)}
          onChange={(e) => e.target.value.trim()}
          label="Username"
          error={errors.username}
          errorMessage={errors?.username?.message}
          placeholder="username"
          name="username"
          type="text"
        />
        <Button variant="themed" block>
          Continue
        </Button>
      </form>
    </div>
  );
};

export default AccountSetup;
