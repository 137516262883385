import React from 'react';

import AccountSetup from '../AccountSetup';
import EnterVerificationCode from '../EnterVerificationCode';
import SelectCountry from '../SelectCountry';
import VerifyPhoneForm from '../VerifyPhoneForm';

import styles from './verification-flow.module.scss';

const VerificationFlow = ({ verificationStep, setVerificationStep, ...props }) => {

  const renderVerificationStep = (step) => {
    switch (step) {
      case 1:
        return <AccountSetup setVerificationStep={setVerificationStep} onSubmit={props.handleSetupAccount} />;
      case 2:
        return <VerifyPhoneForm setVerificationStep={setVerificationStep} onSubmit={props.handleVerifyPhone} />;
      case 3:
        return <EnterVerificationCode setVerificationStep={setVerificationStep} onSubmit={props.handleSubmitVerificationCode} />;
      case 4:
        return <SelectCountry setVerificationStep={setVerificationStep} onSubmit={props.handleSelectCountry} />;
      case 5:
        return '';
      default:
        return <AccountSetup setVerificationStep={setVerificationStep} />;
    }
  };
  
  return (
    <div className={styles.verificationFlow}>
      <div className={styles.verificationFlow_wrapper}>
        <span className={styles.verificationFlow_wrapper_currentStep}>{verificationStep}</span>
        <span className={styles.verificationFlow_wrapper_totalStep}>/4</span>
        <div className={styles.verificationFlow_wrapper_formContainer}>
          {renderVerificationStep(verificationStep)}
        </div>
      </div>
    </div>
  );
};

export default VerificationFlow;
