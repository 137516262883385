import {
  ADD_PERSON_TO_WAITLIST_REQUEST,
  ADD_PERSON_TO_WAITLIST_SUCCESS,
  ADD_PERSON_TO_WAITLIST_FAILURE,
} from './types';

import asyncWrapper from 'src/helpers/asyncWrapper';
import { handleError } from 'src/helpers/errorHandlers';

/**
 *
 * @param {object} personInfo
 * @param {function} successCb Success callback
 */
export const addPersonToWaitList = (personInfo, successCb) => ({
  types: [
    ADD_PERSON_TO_WAITLIST_REQUEST,
    ADD_PERSON_TO_WAITLIST_SUCCESS,
    ADD_PERSON_TO_WAITLIST_FAILURE,
  ],
  promise: async ({ accountsApiClient, personsApiClient, transactionsApiClient, auth }) => {
    const [result, err] = await asyncWrapper(
      personsApiClient.post('/persons/register', { ...personInfo }),
    );

    if (err) {
      handleError(err);
    }

    if (result) {
      successCb();
      return result;
    }
  },
});
