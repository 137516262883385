import React from 'react';
import Images from 'src/assets/images';

import styles from './section-two.module.scss';

const SectionTwo = () => {
  return (
    <section className={styles.sectionTwo}>

      <div className={styles.sectionTwo_header}>
        <h1 className={styles.sectionTwo_header_title}>Designed & Built just for you.</h1>
        <p className={styles.sectionTwo_header_subtitle}>We designed Troperial for you and your FX needs.</p>
      </div>

      <div className={styles.sectionTwo_scrollableWrapper}>
        <div className={styles.sectionTwo_scrollableWrapper_card}>
          <p className={styles.sectionTwo_scrollableWrapper_card_quote}>"I have some dormant cash; I want to put it to work in another currency"</p>
          <img src={Images.person1} />
        </div>
        <div className={styles.sectionTwo_scrollableWrapper_card}>
          <p className={styles.sectionTwo_scrollableWrapper_card_quote}>"I just want to send money to my family fast"</p>
          <img src={Images.person2} />
        </div>
        <div className={styles.sectionTwo_scrollableWrapper_card}>
          <p className={styles.sectionTwo_scrollableWrapper_card_quote}>"I'm in a new country and I just need to do a quick currency exchange"</p>
          <img src={Images.person3} />
        </div>
      </div>
      
    </section>
  );
};

export default SectionTwo;
