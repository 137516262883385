import React from 'react';
import { Route, Redirect} from 'react-router-dom';
import { linkToAppHomePage, linkToForgotPasswordPage, linkToSignInPage, linkToSignUpPage } from 'src/data/pageRoutes';
import WebsiteLayout from 'src/layouts/website-layout';

function PublicRoute(props) {
  const { component: Component, restricted, isAuthenticated, ...rest } = props;

  const render = (props) => {
    if (isAuthenticated && restricted) {
      return <Redirect to={linkToAppHomePage} />;
    }
     
    switch(props.location.pathname) {
      case linkToSignInPage: 
      return <Component {...props} />;
      case linkToSignUpPage: 
      return <Component {...props} />;
      case linkToForgotPasswordPage:
      return <Component {...props} />;
      default: 
      return <WebsiteLayout><Component {...props} /></WebsiteLayout>;
    }
  };

  return <Route {...rest} render={render} />;
}

PublicRoute.defaultProps = {
  restricted: false,
};

export default PublicRoute;
