import React from 'react';
import styles from './photo-picker.module.scss';

const PhotoPicker = ({ onChange, type, accept, capture, children }) => {
  return (
    <div className={styles.FilePicker}>
      <input className={styles.FilePicker_input} onChange={onChange} type={type} accept={accept} capture={capture} />
      {children}
    </div>
  );
};

PhotoPicker.defaultProps = {
  onChange: () => {},
  type: 'file',
  accept: 'image/*',
  capture: 'camera',
};
export default PhotoPicker;
