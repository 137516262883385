// Website Pages
export const linkToHomePage = '/';
export const linkToAboutPage = '/about';
export const linkToHowItWorksPage = '/how-it-works';
export const linkToHelpPage = '/help';
export const linkToMarkNewsPage = '/news';

// Auth Pages
export const linkToSignInPage = '/signin';
export const linkToSignUpPage = '/signup';
export const linkToForgotPasswordPage = '/forgot-password';

// App Pages

export const linkToAppHomePage = '/home';
export const linkToListingsPage = '/listings';
export const linkToProfilePage = '/profile';
export const linkToMessagesPage = '/messages';
export const linkToNotificationsPage = '/notifications'

export const linkToTroperialSupportEmail = 'mailto:support@troperial.com';
