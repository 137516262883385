import { SET_MESSAGES_VIEW, SET_MESSAGES_FEED_INVIEW } from './types';

const initialState = {
  messagesView: {
    displayConversationList: true,
    displayChatView: false,
    displayUserChatProfile: false,
  },

  messagesFeedInView: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_MESSAGES_VIEW:
      return {
        ...state,
        messagesView: action.payload,
      };
    case SET_MESSAGES_FEED_INVIEW:
      return {
        ...state,
        messagesFeedInView: action.payload,
      };
    default:
      return state;
  }
}
