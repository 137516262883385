// SIGN UP
export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';

// SIGN IN
export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';

// SIGN OUT
export const SIGN_OUT_REQUEST = 'SIGN_OUT_REQUEST';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const SIGN_OUT_FAILURE = 'SIGN_OUT_FAILURE';

// AUTHENTICATED USER
export const GET_AUTHENTICATED_USER_REQUEST = 'GET_AUTHENTICATED_USER_REQUEST';
export const GET_AUTHENTICATED_USER_SUCCESS = 'GET_AUTHENTICATED_USER_SUCCESS';
export const GET_AUTHENTICATED_USER_FAILURE = 'GET_AUTHENTICATED_USER_FAILURE';

// AUTHENTICATED USER INFO
export const GET_LOGGED_IN_USER_INFO_REQUEST = 'GET_LOGGED_IN_USER_INFO_REQUEST';
export const GET_LOGGED_IN_USER_INFO_SUCCESS = 'GET_LOGGED_IN_USER_INFO_SUCCESS';
export const GET_LOGGED_IN_USER_INFO_FAILURE = 'GET_LOGGED_IN_USER_INFO_FAILURE';
